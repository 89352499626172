import React, { Component } from "react";
import Heading1 from "../atoms/headings/Heading1";
import Heading3 from "../atoms/headings/Heading3";
import AreaFont from "../molecules/area/AreaFont";
import JapaneseTermsOfService from "../../Resources/JapaneseTermsOfService";

class TermsOfService extends Component {
  render() {
    return (
      <React.Fragment>
        <Heading1>{JapaneseTermsOfService.heading}</Heading1>
        <Heading3>{JapaneseTermsOfService.article1.heading}</Heading3>
        <AreaFont>
          {JapaneseTermsOfService.article1.text.j001}
          <br />
          {JapaneseTermsOfService.article1.text.j002}
          <br />
          {JapaneseTermsOfService.article1.text.j003}
          <br />
          {JapaneseTermsOfService.article1.text.j004}
          <br />
          {JapaneseTermsOfService.article1.text.j005}
          <br />
          {JapaneseTermsOfService.article1.text.j006}
          <br />
          {JapaneseTermsOfService.article1.text.j007}
          <br />
          {JapaneseTermsOfService.article1.text.j008}
          <br />
          {JapaneseTermsOfService.article1.text.j009}
          <br />
          {JapaneseTermsOfService.article1.text.j010}
          <br />
          {JapaneseTermsOfService.article1.text.j011}
          <br />
          {JapaneseTermsOfService.article1.text.j012}
          <br />
          {JapaneseTermsOfService.article1.text.j013}
          <br />
          {JapaneseTermsOfService.article1.text.j014}
          <br />
          {JapaneseTermsOfService.article1.text.j015}
          <br />
          {JapaneseTermsOfService.article1.text.j016}
          <br />
          {JapaneseTermsOfService.article1.text.j017}
          <br />
          {JapaneseTermsOfService.article1.text.j018}
        </AreaFont>
        <Heading3>{JapaneseTermsOfService.article2.heading}</Heading3>
        <AreaFont>
          {JapaneseTermsOfService.article2.text.j001}
          <br />
          {JapaneseTermsOfService.article2.text.j002}
          <br />
          {JapaneseTermsOfService.article2.text.j003}
          <br />
          {JapaneseTermsOfService.article2.text.j004}
          <br />
          {JapaneseTermsOfService.article2.text.j005}
          <br />
          {JapaneseTermsOfService.article2.text.j006}
          <br />
          {JapaneseTermsOfService.article2.text.j007}
          <br />
          {JapaneseTermsOfService.article2.text.j008}
          <br />
          {JapaneseTermsOfService.article2.text.j009}
          <br />
          {JapaneseTermsOfService.article2.text.j010}
          <br />
          {JapaneseTermsOfService.article2.text.j011}
          <br />
          {JapaneseTermsOfService.article2.text.j012}
          <br />
          {JapaneseTermsOfService.article2.text.j013}
          <br />
          {JapaneseTermsOfService.article2.text.j014}
          <br />
          {JapaneseTermsOfService.article2.text.j015}
          <br />
          {JapaneseTermsOfService.article2.text.j016}
          <br />
          {JapaneseTermsOfService.article2.text.j017}
          <br />
          {JapaneseTermsOfService.article2.text.j018}
          <br />
          {JapaneseTermsOfService.article2.text.j019}
          <br />
          {JapaneseTermsOfService.article2.text.j020}
          <br />
          {JapaneseTermsOfService.article2.text.j021}
          <br />
          {JapaneseTermsOfService.article2.text.j022}
          <br />
          {JapaneseTermsOfService.article2.text.j023}
          <br />
          {JapaneseTermsOfService.article2.text.j024}
          <br />
          {JapaneseTermsOfService.article2.text.j025}
        </AreaFont>
        <Heading3>{JapaneseTermsOfService.article3.heading}</Heading3>
        <AreaFont>
          {JapaneseTermsOfService.article3.text.j001}
          <br />
          {JapaneseTermsOfService.article3.text.j002}
          <br />
          {JapaneseTermsOfService.article3.text.j003}
          <br />
          {JapaneseTermsOfService.article3.text.j004}
          <br />
          {JapaneseTermsOfService.article3.text.j005}
          <br />
          {JapaneseTermsOfService.article3.text.j006}
          <br />
          {JapaneseTermsOfService.article3.text.j007}
          <br />
          {JapaneseTermsOfService.article3.text.j008}
          <br />
          {JapaneseTermsOfService.article3.text.j009}
          <br />
          {JapaneseTermsOfService.article3.text.j010}
          <br />
          {JapaneseTermsOfService.article3.text.j011}
        </AreaFont>
        <Heading3>{JapaneseTermsOfService.article4.heading}</Heading3>
        <AreaFont>
          {JapaneseTermsOfService.article4.text.j001}
          <br />
          {JapaneseTermsOfService.article4.text.j002}
          <br />
          {JapaneseTermsOfService.article4.text.j003}
          <br />
          {JapaneseTermsOfService.article4.text.j004}
          <br />
          {JapaneseTermsOfService.article4.text.j005}
          <br />
          {JapaneseTermsOfService.article4.text.j006}
          <br />
          {JapaneseTermsOfService.article4.text.j007}
          <br />
          {JapaneseTermsOfService.article4.text.j008}
          <br />
          {JapaneseTermsOfService.article4.text.j009}
          <br />
          {JapaneseTermsOfService.article4.text.j010}
          <br />
          {JapaneseTermsOfService.article4.text.j011}
          <br />
          {JapaneseTermsOfService.article4.text.j012}
          <br />
          {JapaneseTermsOfService.article4.text.j013}
          <br />
          {JapaneseTermsOfService.article4.text.j014}
          <br />
          {JapaneseTermsOfService.article4.text.j015}
          <br />
          {JapaneseTermsOfService.article4.text.j016}
          <br />
          {JapaneseTermsOfService.article4.text.j017}
          <br />
          {JapaneseTermsOfService.article4.text.j018}
          <br />
          {JapaneseTermsOfService.article4.text.j019}
          <br />
          {JapaneseTermsOfService.article4.text.j020}
        </AreaFont>
        <Heading3>{JapaneseTermsOfService.article5.heading}</Heading3>
        <AreaFont>
          {JapaneseTermsOfService.article5.text.j001}
          <br />
          {JapaneseTermsOfService.article5.text.j002}
          <br />
          {JapaneseTermsOfService.article5.text.j003}
          <br />
          {JapaneseTermsOfService.article5.text.j004}
          <br />
          {JapaneseTermsOfService.article5.text.j005}
          <br />
          {JapaneseTermsOfService.article5.text.j006}
        </AreaFont>
        <Heading3>{JapaneseTermsOfService.article6.heading}</Heading3>
        <AreaFont>
          {JapaneseTermsOfService.article6.text.j001}
          <br />
          {JapaneseTermsOfService.article6.text.j002}
          <br />
          {JapaneseTermsOfService.article6.text.j003}
          <br />
          {JapaneseTermsOfService.article6.text.j004}
          <br />
          {JapaneseTermsOfService.article6.text.j005}
        </AreaFont>
        <Heading3>{JapaneseTermsOfService.article7.heading}</Heading3>
        <AreaFont>{JapaneseTermsOfService.article7.text.j001}</AreaFont>
        <Heading3>{JapaneseTermsOfService.article8.heading}</Heading3>
        <AreaFont>{JapaneseTermsOfService.article8.text.j001}</AreaFont>
        <Heading3>{JapaneseTermsOfService.article9.heading}</Heading3>
        <AreaFont>
          {JapaneseTermsOfService.article9.text.j001}
          <br />
          {JapaneseTermsOfService.article9.text.j002}
        </AreaFont>
        <Heading3>{JapaneseTermsOfService.article10.heading}</Heading3>
        <AreaFont>
          {JapaneseTermsOfService.article10.text.j001}
          <br />
          {JapaneseTermsOfService.article10.text.j002}
          <br />
          {JapaneseTermsOfService.article10.text.j003}
          <br />
          {JapaneseTermsOfService.article10.text.j004}
          <br />
          {JapaneseTermsOfService.article10.text.j005}
          <br />
          {JapaneseTermsOfService.article10.text.j006}
          <br />
          {JapaneseTermsOfService.article10.text.j007}
          <br />
          {JapaneseTermsOfService.article10.text.j008}
          <br />
          {JapaneseTermsOfService.article10.text.j009}
        </AreaFont>
        <Heading3>{JapaneseTermsOfService.article11.heading}</Heading3>
        <AreaFont>
          {JapaneseTermsOfService.article11.text.j001}
          <br />
          {JapaneseTermsOfService.article11.text.j002}
          <br />
          {JapaneseTermsOfService.article11.text.j003}
        </AreaFont>
        <Heading3>{JapaneseTermsOfService.article12.heading}</Heading3>
        <AreaFont>{JapaneseTermsOfService.article12.text.j001}</AreaFont>
        <Heading3>{JapaneseTermsOfService.article13.heading}</Heading3>
        <AreaFont>
          {JapaneseTermsOfService.article13.text.j001}
          <br />
          {JapaneseTermsOfService.article13.text.j002}
          <br />
          {JapaneseTermsOfService.article13.text.j003}
        </AreaFont>
        <Heading3>{JapaneseTermsOfService.article14.heading}</Heading3>
        <AreaFont>
          {JapaneseTermsOfService.article14.text.j001}
          <br />
          {JapaneseTermsOfService.article14.text.j002}
          <br />
          {JapaneseTermsOfService.article14.text.j003}
          <br />
          {JapaneseTermsOfService.article14.text.j004}
          <br />
          {JapaneseTermsOfService.article14.text.j005}
          <br />
          {JapaneseTermsOfService.article14.text.j006}
          <br />
          {JapaneseTermsOfService.article14.text.j007}
          <br />
          {JapaneseTermsOfService.article14.text.j008}
        </AreaFont>
        <Heading3>{JapaneseTermsOfService.article15.heading}</Heading3>
        <AreaFont>
          {JapaneseTermsOfService.article15.text.j001}
          <br />
          {JapaneseTermsOfService.article15.text.j002}
          <br />
          {JapaneseTermsOfService.article15.text.j003}
          <br />
          {JapaneseTermsOfService.article15.text.j004}
          <br />
          {JapaneseTermsOfService.article15.text.j005}
          <br />
          {JapaneseTermsOfService.article15.text.j006}
          <br />
          {JapaneseTermsOfService.article15.text.j007}
          <br />
          {JapaneseTermsOfService.article15.text.j008}
          <br />
          {JapaneseTermsOfService.article15.text.j009}
          <br />
          {JapaneseTermsOfService.article15.text.j010}
        </AreaFont>
        <Heading3>{JapaneseTermsOfService.article16.heading}</Heading3>
        <AreaFont>
          {JapaneseTermsOfService.article16.text.j001}
          <br />
          {JapaneseTermsOfService.article16.text.j002}
          <br />
          {JapaneseTermsOfService.article16.text.j003}
          <br />
          {JapaneseTermsOfService.article16.text.j004}
          <br />
          {JapaneseTermsOfService.article16.text.j005}
          <br />
          {JapaneseTermsOfService.article16.text.j006}
          <br />
          {JapaneseTermsOfService.article16.text.j007}
          <br />
          {JapaneseTermsOfService.article16.text.j008}
          <br />
          {JapaneseTermsOfService.article16.text.j009}
          <br />
          {JapaneseTermsOfService.article16.text.j010}
          <br />
          {JapaneseTermsOfService.article16.text.j011}
          <br />
          {JapaneseTermsOfService.article16.text.j012}
          <br />
          {JapaneseTermsOfService.article16.text.j013}
          <br />
          {JapaneseTermsOfService.article16.text.j014}
        </AreaFont>
        <Heading3>{JapaneseTermsOfService.article17.heading}</Heading3>
        <AreaFont>
          {JapaneseTermsOfService.article17.text.j001}
          <br />
          {JapaneseTermsOfService.article17.text.j002}
          <br />
          {JapaneseTermsOfService.article17.text.j003}
        </AreaFont>
        <Heading3>{JapaneseTermsOfService.article18.heading}</Heading3>
        <AreaFont>
          {JapaneseTermsOfService.article18.text.j001}
          <br />
          {JapaneseTermsOfService.article18.text.j002}
          <br />
          {JapaneseTermsOfService.article18.text.j003}
          <br />
          {JapaneseTermsOfService.article18.text.j004}
          <br />
          {JapaneseTermsOfService.article18.text.j005}
          <br />
          {JapaneseTermsOfService.article18.text.j006}
          <br />
          {JapaneseTermsOfService.article18.text.j007}
          <br />
          {JapaneseTermsOfService.article18.text.j008}
          <br />
          {JapaneseTermsOfService.article18.text.j009}
          <br />
          {JapaneseTermsOfService.article18.text.j010}
          <br />
          {JapaneseTermsOfService.article18.text.j011}
        </AreaFont>
        <Heading3>{JapaneseTermsOfService.article19.heading}</Heading3>
        <AreaFont>
          {JapaneseTermsOfService.article19.text.j001}
          <br />
          {JapaneseTermsOfService.article19.text.j002}
          <br />
          {JapaneseTermsOfService.article19.text.j003}
          <br />
          {JapaneseTermsOfService.article19.text.j004}
          <br />
          {JapaneseTermsOfService.article19.text.j005}
          <br />
          {JapaneseTermsOfService.article19.text.j006}
          <br />
          {JapaneseTermsOfService.article19.text.j007}
          <br />
          {JapaneseTermsOfService.article19.text.j008}
          <br />
          {JapaneseTermsOfService.article19.text.j009}
          <br />
          {JapaneseTermsOfService.article19.text.j010}
          <br />
          {JapaneseTermsOfService.article19.text.j011}
        </AreaFont>
        <Heading3>{JapaneseTermsOfService.article20.heading}</Heading3>
        <AreaFont>
          {JapaneseTermsOfService.article20.text.j001}
          <br />
          {JapaneseTermsOfService.article20.text.j002}
          <br />
          {JapaneseTermsOfService.article20.text.j003}
          <br />
          {JapaneseTermsOfService.article20.text.j004}
          <br />
          {JapaneseTermsOfService.article20.text.j005}
          <br />
          {JapaneseTermsOfService.article20.text.j006}
          <br />
          {JapaneseTermsOfService.article20.text.j007}
        </AreaFont>
        <Heading3>{JapaneseTermsOfService.article21.heading}</Heading3>
        <AreaFont>{JapaneseTermsOfService.article21.text.j001}</AreaFont>
        <Heading3>{JapaneseTermsOfService.article22.heading}</Heading3>
        <AreaFont>
          {JapaneseTermsOfService.article22.text.j001}
          <br />
          {JapaneseTermsOfService.article22.text.j002}
        </AreaFont>
        <Heading3>{JapaneseTermsOfService.article23.heading}</Heading3>
        <AreaFont>{JapaneseTermsOfService.article23.text.j001}</AreaFont>
        <Heading3>{JapaneseTermsOfService.article24.heading}</Heading3>
        <AreaFont>
          {JapaneseTermsOfService.article24.text.j001}
          <br />
          {JapaneseTermsOfService.article24.text.j002}
        </AreaFont>
        <AreaFont>
          {JapaneseTermsOfService.date.j001}
          <br />
          {JapaneseTermsOfService.date.j002}
          <br />
          {JapaneseTermsOfService.date.j003}
          <br />
          {JapaneseTermsOfService.date.j004}
          <br />
          {JapaneseTermsOfService.date.j005}
          <br />
          {JapaneseTermsOfService.date.j006}
          <br />
          {JapaneseTermsOfService.date.j007}
          <br />
          {JapaneseTermsOfService.date.j008}
          <br />
          {JapaneseTermsOfService.date.j009}
          <br />
          {JapaneseTermsOfService.date.j0010}
          <br />
          {JapaneseTermsOfService.date.j0011}
          <br />
        </AreaFont>
        <Heading3>{JapaneseTermsOfService.cancelPolicy.heading}</Heading3>
        <AreaFont>
          {JapaneseTermsOfService.cancelPolicy.text.j001}
          <br />
          {JapaneseTermsOfService.cancelPolicy.text.j002}
          <br />
          {JapaneseTermsOfService.cancelPolicy.text.j003}
          <br />
          {JapaneseTermsOfService.cancelPolicy.text.j004}
          <br />
          {JapaneseTermsOfService.cancelPolicy.text.j005}
          <br />
          {JapaneseTermsOfService.cancelPolicy.text.j006}
          <br />
          {JapaneseTermsOfService.cancelPolicy.text.j007}
          <br />
          {JapaneseTermsOfService.cancelPolicy.text.j008}
          <br />
          {JapaneseTermsOfService.cancelPolicy.text.j009}
          <br />
          {JapaneseTermsOfService.cancelPolicy.text.j010}
          <br />
          {JapaneseTermsOfService.cancelPolicy.text.j011}
          <br />
          {JapaneseTermsOfService.cancelPolicy.text.j012}
          <br />
          {JapaneseTermsOfService.cancelPolicy.text.j013}
          <br />
          {JapaneseTermsOfService.cancelPolicy.text.j014}
          <br />
          {JapaneseTermsOfService.cancelPolicy.text.j015}
        </AreaFont>
      </React.Fragment>
    );
  }
}

export default TermsOfService;
