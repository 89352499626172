import React, { Component } from "react";
import Heading1 from "../../atoms/headings/Heading1";
import HospitalImage from "../../molecules/HospitalImage";
import HospitalInfo from "../../molecules/HospitalInfo";
import MedicalCareMenu from "./MedicalCareMenu";
import { japaneseList } from "../../../Resources/japaneseList";

class HospitalDetail extends Component {
  render() {
    const renderResource = japaneseList.organisms.HospitalDetail.render;
    const { hospital, cardStatus, addressStatus } = this.props;

    return (
      <React.Fragment>
        <Heading1>{renderResource.div.PrimaryHeading.j001}</Heading1>
        <HospitalImage hospital={hospital} />
        <HospitalInfo hospital={hospital} />
        {hospital.menus && hospital.menus.length > 0 && (
          <MedicalCareMenu
            hospital={hospital}
            cardStatus={cardStatus}
            addressStatus={addressStatus}
          />
        )}
      </React.Fragment>
    );
  }
}

export default HospitalDetail;
