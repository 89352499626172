import gql from "graphql-tag";

export const ListAppointments = gql`
  query ListAppointments {
    appointments {
      items {
        hospitalId
        createTime
        from
        to
        doctorName
        status
        hospitalName
      }
    }
  }
`;

export const ListAppointmentsByStatus = gql`
  query ListAppointments(
    $status: [ExamStatus]
    $numberOfItems: Int
    $facilityType: [String]
    $startToken: String
    $orderBy: OrderBy
  ) {
    appointments(
      status: $status
      numberOfItems: $numberOfItems
      facilityType: $facilityType
      startToken: $startToken
      orderBy: $orderBy
    ) {
      items {
        hospitalId
        createTime
        fromOfSlot
        from
        to
        status
        hospitalName
        canCreateAppointment
      }
      nextToken
    }
  }
`;
export const ListAppointmentsWithConsentByStatus = gql`
  query ListAppointmentsWithConsent(
    $status: [ExamStatus]
    $numberOfItems: Int
    $facilityType: [String]
    $startToken: String
    $orderBy: OrderBy
  ) {
    appointmentsWithConsent(
      status: $status
      numberOfItems: $numberOfItems
      facilityType: $facilityType
      startToken: $startToken
      orderBy: $orderBy
    ) {
      items {
        consentStatus
        isSupportOnlineQualificationSystem
        hospitalId
        createTime
        fromOfSlot
        from
        to
        status
        hospitalName
        canCreateAppointment
        menu {
          medicalMethod
        }
      }
      nextToken
    }
  }
`;

export const ListAppointmentsOfPatient = gql`
  query ListAppointmentsOfPatient(
    $start: String
    $end: String
    $status: [ExamStatus]
  ) {
    appointments(start: $start, end: $end, status: $status) {
      items {
        hospitalId
        createTime
        from
        to
        status
        hospitalName
        confirmAttachmentStatus
        attachment {
          status
          expiredTime
          updatedTime
        }
        menu {
          supportsInsuranceTreatment
          medicalMenu
          medicalMethod
          displayName
        }
        payment {
          errorCode
        }
      }
    }
  }
`;

export const ListAppointmentsOfDoctor = gql`
  query ListAppointmentsOfDoctor(
    $start: String
    $end: String
    $medicalMethod: String
    $doctorId: String!
    $hospitalId: String!
    $status: [ExamStatus]
  ) {
    doctorAppointments(
      start: $start
      end: $end
      doctorId: $doctorId
      hospitalId: $hospitalId
      medicalMethod: $medicalMethod
      status: $status
    ) {
      items {
        hospitalId
        createTime
        from
        to
        status
        hospitalName
      }
    }
  }
`;

export const GetApptStatusReservationDayPatientAttachments = gql`
  query GetAppointment($createTime: ID!) {
    appointment(createTime: $createTime) {
      status
      from
      patientAttachments {
        fileName
        fileType
        filePath
        thumbnailFilePath
        uploadedTime
        updatedTime
      }
    }
  }
`;

export const GetAppointment = gql`
  query GetAppointment($createTime: ID!) {
    appointment(createTime: $createTime) {
      hospitalId
      hospitalName
      createTime
      fromOfSlot
      from
      to
      status
      roomName
      hasCharge
      facilityType
      interviewSheetAnswerStatus
      patientId
      payment {
        medicalExpense
        orderId
        paymentTime
        status
        usageFee
        usageFeeTax
        usageFeeTaxIncluded
        deliveryTotalFee
        deliveryStatus
        deliverySelectedOption
        errorCode
        errorMedicalExpense
        selfFundedFee
      }
      menu {
        menuId
        doctorId
        doctorName
        usageFee
        examFeeDescription
        order
        description
        medicalMethod
        medicalMenu
        paymentMethod
        patientCategory
        displayName
        requiredCreditCardRegister
        requiredAddressRegister
        assignToDoctor
        cancelPolicyContent
        cancelAppointmentByPatient
        createAppointmentByPatient
        availableOnlyIndustrialUser
        showFeeAndTax
        showPaymentDetailAndCancelPolicy
        createAppointmentByDoctor
        helpDialogContent {
          allowPatientMakeAppointment
          denyPatientMakeAppointment
        }
        supportsInsuranceTreatment
        supportPaymentByContractCompany
      }
      attachment {
        expiredTime
        fileName
        filePath
        thumbnailFilePath
        status
        updatedTime
      }
      patientAttachments {
        fileName
        fileType
        filePath
        thumbnailFilePath
        uploadedTime
        updatedTime
      }
      rejectSelectedOption
      rejectComment
      program {
        programId
        type
        name
      }
      deliveryFeeCalculateType
      medicineDelivery {
        isEditable
        displaySites
        accountStatus
        deliveryMethod
        deliveryHospitalId
        name
        postalCode
        prefectureCode
        addressCity
        addressStreetNumber
        addressBuilding
        tel
      }
    }
  }
`;

export const GetSlots = gql`
  query GetSlots(
    $hospitalId: String!
    $start: String
    $end: String
    $medicalMethod: String
  ) {
    slots(
      hospitalId: $hospitalId
      start: $start
      end: $end
      medicalMethod: $medicalMethod
    ) {
      items {
        hospitalId
        fromOfSlot
        from
        to
        expirationDate
        menuId_from_doctorId
        capacity
        remaining
        locked
        menu {
          doctorId
          doctorName
          medicalMethod
        }
      }
    }
  }
`;

export const RegisterAppointment = gql`
  mutation RegisterAppointment(
    $hospitalId: ID!
    $menuId: Int!
    $fromOfSlot: String!
    $from: String!
    $to: String!
    $content: String!
    $companyName: String!
    $sideEffect: AppointmentSideEffect!
    $sendSms: Boolean
    $sendMail: Boolean
    $doctorId: String
    $interviewSheetAnswer: InputInterviewSheetAnswer
    $patientAttachments: [InputPatientAttachment]
    $medicineDelivery: InputMedicineDelivery
  ) {
    registerAppointment(
      hospitalId: $hospitalId
      fromOfSlot: $fromOfSlot
      from: $from
      to: $to
      menuId: $menuId
      content: $content
      companyName: $companyName
      sideEffect: $sideEffect
      sendSms: $sendSms
      sendMail: $sendMail
      doctorId: $doctorId
      interviewSheetAnswer: $interviewSheetAnswer
      patientAttachments: $patientAttachments
      medicineDelivery: $medicineDelivery
    ) {
      createTime
      hospitalId
      from
    }
  }
`;

export const CancelAppointment = gql`
  mutation CancelAppointment(
    $createTime: String!
    $medicalMethod: String!
    $fromOfSlot: String!
  ) {
    cancelAppointment(
      createTime: $createTime
      medicalMethod: $medicalMethod
      fromOfSlot: $fromOfSlot
    ) {
      hospitalId
      from
      to
      status
    }
  }
`;

export const Account = gql`
  query Account {
    account {
      patientId
      cardStatus
      familyName
      givenName
      familyNameKana
      givenNameKana
      employeeNumber
      gender
      birthdate
      phoneNumber
      organizationId
      organization {
        name
        featureSettings {
          featureId
          type
          enable
          name
        }
        supportPrograms {
          programId
          enable
          type
          # name
          supportInsuranceTreatment
          deliveryFeeCalculateType
          paymentMethod
        }
      }
      enterprise {
        enterpriseId
        enterpriseName
        contractPlans {
          contractPlanId
          contractPlanName
          selfFundedAmount
        }
      }
      address {
        postalCode
        prefectureCode
        addressCity
        addressStreetNumber
        addressBuilding
      }
      insuranceCards {
        fileName
        filePath
        fileType
        updatedTime
      }
    }
  }
`;

export const GetTwilioToken = gql`
  query GetTwilioToken {
    twilio {
      token
      appointmentKey
      startTime
    }
  }
`;

export const StandbyAppointment = gql`
  query StandbyAppointment {
    standbyAppointment {
      isStandby
      appointment {
        hospitalId
        hospitalName
        createTime
        from
        to
        status
      }
    }
  }
`;

export const GetMenuMaster = gql`
  query GetMenuMaster($medicalMenu: String, $medicalMethod: String) {
    menuMaster(medicalMenu: $medicalMenu, medicalMethod: $medicalMethod) {
      requiredCreditCardRegister
      requiredAddressRegister
      createAppointmentByDoctor
      availableOnlyIndustrialUser
      showFeeAndTax
      showPaymentDetailAndCancelPolicy
      assignToDoctor
      cancelPolicyContent
      cancelAppointmentByPatient
      createAppointmentByPatient
      displayName
      helpDialogContent {
        allowPatientMakeAppointment
        denyPatientMakeAppointment
      }
      medicalMenu
      medicalMethod
      requiredCreditCardRegister
      showFeeAndTax
      showPaymentDetailAndCancelPolicy
      endVideoChatContent {
        finishPageTitle
        confirmPrice {
          body
          title
        }
        contactUs {
          body
          title
        }
        inThisCase {
          body
          title
        }
        medicineDelivery {
          body
          title
        }
      }
    }
  }
`;

export const GetAnnouncement = gql`
  query GetAnnouncement {
    announcement {
      from
      to
    }
  }
`;

export const ListHospitalsProgram = gql`
  query ListHospitalsProgram($from: String) {
    hospitalsProgram(from: $from) {
      hospitalId
      name
      address
      facilityType
      menus {
        medicalMethod
        medicalMenu
        createAppointmentByPatient
        displayName
      }
    }
  }
`;

export const Hospital = gql`
  query Hospital($hospitalId: ID!, $from: String) {
    hospital(hospitalId: $hospitalId, from: $from) {
      hospitalId
      name
      facilityType
      address
      description
      url
      tel
      doctors {
        doctorId
        order
        doctorName
      }
      medicalCourses {
        title
      }
      menus {
        menuId
        doctorId
        doctorName
        usageFee
        examFeeDescription
        order
        description
        medicalMenu
        medicalMethod
        serviceCategory
        displayName
        patientCategory
        paymentMethod
        requiredAddressRegister
        requiredCreditCardRegister
        assignToDoctor
        cancelPolicyContent
        cancelAppointmentByPatient
        createAppointmentByDoctor
        createAppointmentByPatient
        availableOnlyIndustrialUser
        showExamFee
        showFeeAndTax
        showPaymentDetailAndCancelPolicy
        helpDialogContent {
          allowPatientMakeAppointment
          denyPatientMakeAppointment
        }
        supportsInsuranceTreatment
        supportPaymentByContractCompany
        approvalRequired
        requiredCompanyName
        contentType
        interviewSheetId
        enableAppointmentFlow
        extarnal_system_url
        appointmentFlows {
          approvalRequired
          steps {
            name
            order
            contents {
              title
              description
              note
              order
            }
          }
        }
        telephoneAppointmentNumber
        contractPlanId
        enableMedicineDelivery
      }
    }
  }
`;

export const RegisterAddress = gql`
  mutation RegisterAddress(
    $postalCode: String!
    $prefectureCode: String!
    $addressCity: String!
    $addressStreetNumber: String!
    $addressBuilding: String!
  ) {
    registerAddress(
      postalCode: $postalCode
      prefectureCode: $prefectureCode
      addressCity: $addressCity
      addressStreetNumber: $addressStreetNumber
      addressBuilding: $addressBuilding
    )
  }
`;

export const UpdatePatient = gql`
  mutation UpdatePatient(
    $familyName: String!
    $givenName: String!
    $familyNameKana: String!
    $givenNameKana: String!
    $gender: String!
    $birthdate: String!
    $employeeNumber: String
  ) {
    updatePatient(
      familyName: $familyName
      givenName: $givenName
      familyNameKana: $familyNameKana
      givenNameKana: $givenNameKana
      gender: $gender
      birthdate: $birthdate
      employeeNumber: $employeeNumber
    )
  }
`;

export const UpdateLastLoginTime = gql`
  mutation UpdateLastLoginTime($videoStandby: VideoStandby!) {
    updateLastLoginTime(videoStandby: $videoStandby)
  }
`;
export const GenerateGetPresignedUrl = gql`
  mutation GenerateGetPresignedUrl($filePath: String!) {
    generateGetPresignedUrl(filePath: $filePath) {
      getSignedRequest
    }
  }
`;

export const GetInsuranceCardPresignedUrl = gql`
  mutation GetInsuranceCardPresignedUrl($filePath: String!) {
    getInsuranceCardPresignedUrl(filePath: $filePath) {
      getSignedRequest
    }
  }
`;

export const GenerateUploadPresignedUrl = gql`
  mutation GenerateUploadPresignedUrl($fileName: String!, $fileType: String!) {
    generateUploadPresignedUrl(fileName: $fileName, fileType: $fileType) {
      putSignedRequest
      filePath
    }
  }
`;

export const AttachmentPresignedUrlForPutObject = gql`
  query AttachmentPresignedUrlForPutObject(
    $s3FileKey: String!
    $fileType: String!
  ) {
    attachmentPresignedUrlForPutObject(
      s3FileKey: $s3FileKey
      fileType: $fileType
    ) {
      url
    }
  }
`;

export const AttachmentPresignedUrlForGetObject = gql`
  query AttachmentPresignedUrlForGetObject(
    $filePath: String!
    $fileName: String!
  ) {
    attachmentPresignedUrlForGetObject(
      filePath: $filePath
      fileName: $fileName
    ) {
      url
    }
  }
`;

export const UploadInsuranceCard = gql`
  mutation UploadInsuranceCard(
    $fileName: String!
    $fileType: String!
    $filePath: String!
  ) {
    uploadInsuranceCard(
      fileName: $fileName
      fileType: $fileType
      filePath: $filePath
    )
  }
`;

export const DisconnectVideoChat = gql`
  mutation DisconnectVideoChat {
    disconnectVideoChat
  }
`;

export const RegisterServiceLog = gql`
  mutation RegisterServiceLog(
    $eventType: Int!
    $executorType: Int!
    $isSucceeded: Boolean!
    $contents: [Contents]
    $resourceType: String!
  ) {
    registerServiceLog(
      eventType: $eventType
      executorType: $executorType
      isSucceeded: $isSucceeded
      contents: $contents
      resourceType: $resourceType
    )
  }
`;

export const OrganizationHospitalMenu = gql`
  query OrganizationHospitalMenu(
    $organizationId_hospitalId: String
    $medicalMenu_medicalMethod: String
  ) {
    organizationHospitalMenu(
      organizationId_hospitalId: $organizationId_hospitalId
      medicalMenu_medicalMethod: $medicalMenu_medicalMethod
    ) {
      requireCreditCardRegister
      programId
    }
  }
`;

export const UpdatePatientEmail = gql`
  mutation UpdatePatientEmail($email: String!) {
    updatePatientEmail(email: $email)
  }
`;

export const ListHospitalsByAddress = gql`
  query ListHospitals(
    $prefectureCode: String
    $facilityType: String
    $from: String
    $limit: Int
    $startToken: String
  ) {
    hospitals(
      prefectureCode: $prefectureCode
      facilityType: $facilityType
      from: $from
      limit: $limit
      startToken: $startToken
    ) {
      items {
        hospitalId
        facilityType
        name
        address
        addressCity
        addressStreetNumber
        postalCode
        prefectureCode
        menus {
          menuId
          doctorId
          createAppointmentByPatient
          usageFee
          examFeeDescription
          order
          description
          medicalMethod
          medicalMenu
          supportsInsuranceTreatment
          approvalRequired
          canStartCallBeforeMinutes
          name
          displayName
          displayTagName
          patientCategory
        }
      }
      nextToken
    }
  }
`;

export const ListHospitalCountsByAddress = gql`
  query ListHospitalCounts(
    $prefectureCode: String
    $facilityType: String
    $from: String
  ) {
    hospitalCounts(
      prefectureCode: $prefectureCode
      facilityType: $facilityType
      from: $from
    ) {
      items {
        prefectureCode
        count
      }
    }
  }
`;

export const InterviewSheet = gql`
  query InterviewSheet($hospitalId: String!, $interviewSheetId: Int!) {
    interviewSheet(
      hospitalId: $hospitalId
      interviewSheetId: $interviewSheetId
    ) {
      interviewSheetId
      interviewSheetVersion
      interviewSheetName
      interviewSheetItems {
        idNotation
        order
        interviewSheetItemId
        interviewSheetItemVersion
        requiredAnswer
        interviewText
        interviewTextRemarks
        interviewType
        interviewChoices {
          no
          text
        }
        freeTextPlaceholder
        freeTextRemarks
      }
    }
  }
`;

export const UpdateInterviewSheetAnswer = gql`
  mutation UpdateInterviewSheetAnswer(
    $createTime: String!
    $interviewAnswers: [InputInterviewAnswer]!
  ) {
    updateInterviewSheetAnswer(
      createTime: $createTime
      interviewAnswers: $interviewAnswers
    )
  }
`;

export const InterviewSheetAnswer = gql`
  query InterviewSheetAnswer($createTime: String!) {
    interviewSheetAnswer(createTime: $createTime) {
      interviewSheetId
      interviewSheetVersion
      interviewAnswers {
        order
        idNotation
        interviewSheetItemId
        interviewSheetItemVersion
        requiredAnswer
        interviewChoiceNos
        freeText
        interviewText
        interviewTextRemarks
        interviewType
        interviewChoices {
          no
          text
        }
        freeTextPlaceholder
        freeTextRemarks
      }
    }
  }
`;
export const ReleaseTentativeReservation = gql`
  mutation ReleaseTentativeReservation {
    releaseTentativeReservation
  }
`;

export const AddInquirySignupSms = gql`
  mutation AddInquirySignupSms(
    $phoneNumber: String!
    $ua: String!
    $email: String!
  ) {
    addInquirySignupSms(phoneNumber: $phoneNumber, ua: $ua, email: $email)
  }
`;

export const ResendCodeByPhoneCall = gql`
  mutation ResendCodeByPhoneCall($phoneNumber: String!, $flowType: FlowType!) {
    resendCodeByPhoneCall(phoneNumber: $phoneNumber, flowType: $flowType)
  }
`;

export const ListPersonalNotifications = gql`
  query ListPersonalNotifications {
    personalNotifications {
      totalCount
      items {
        notificationId
        notificationType
        notificationDatetime
        isUnread
        hospitalName
        menuDisplayName
        appointmentKey
        appointmentFrom
        appointmentTo
        appointmentStatus
        appointmentCreatedBy
        paymentErrorCode
        interviewSheetAnswerStatus
        existsValidAttachment
        consentStatus
        canCancelConsent
      }
    }
  }
`;

export const ReadPersonalNotification = gql`
  mutation ReadPersonalNotification(
    $notificationId: String!
    $notificationType: NotificationType!
  ) {
    readPersonalNotification(
      notificationId: $notificationId
      notificationType: $notificationType
    )
  }
`;

export const PersonalNotificationsTotalCount = gql`
  query PersonalNotificationsTotalCount($isUnreadOnly: Boolean) {
    personalNotifications(isUnreadOnly: $isUnreadOnly) {
      totalCount
    }
  }
`;

export const PutPatientAttachment = gql`
  mutation putPatientAttachment(
    $appointmentCreateTime: String!
    $attachment: InputPatientAttachment!
  ) {
    putPatientAttachment(
      appointmentCreateTime: $appointmentCreateTime
      attachment: $attachment
    ) {
      fileName
      fileType
      filePath
      thumbnailFilePath
      uploadedTime
      updatedTime
    }
  }
`;

export const DeletePatientAttachment = gql`
  mutation deletePatientAttachment(
    $appointmentCreateTime: String!
    $filePath: String!
  ) {
    deletePatientAttachment(
      appointmentCreateTime: $appointmentCreateTime
      filePath: $filePath
    ) {
      fileName
      fileType
      filePath
      thumbnailFilePath
      uploadedTime
      updatedTime
    }
  }
`;

export const AddLowNetworkHistoryLog = gql`
  mutation AddLowNetworkHistoryLog(
    $appointmentId: String!
    $operationType: OperationType!
  ) {
    addLowNetworkHistoryLog(
      appointmentId: $appointmentId
      operationType: $operationType
    )
  }
`;

export const RegisterEnterpriseAuthorization = gql`
  mutation authorizeEnterprise(
    $mainEffect: AuthorizeEnterpriseMainEffect!
    $enterpriseAuthorizationCodes: [String!]
    $termsOfServiceForEnterpriseAgree: Boolean
    $termsOfServiceForEnterpriseRevisionDate: String
    $employeeNumber: String
    $confirmEmployeeNumber: String
  ) {
    authorizeEnterprise(
      mainEffect: $mainEffect
      enterpriseAuthorizationCodes: $enterpriseAuthorizationCodes
      termsOfServiceForEnterpriseAgree: $termsOfServiceForEnterpriseAgree
      termsOfServiceForEnterpriseRevisionDate: $termsOfServiceForEnterpriseRevisionDate
      employeeNumber: $employeeNumber
      confirmEmployeeNumber: $confirmEmployeeNumber
    ) {
      enterpriseId
      enterpriseName
      contractPlans {
        contractPlanId
        contractPlanName
      }
    }
  }
`;

export const RecommandedPharmacies = gql`
  query RecommandedPharmacies($hospitalId: String!, $siteType: SiteType!) {
    recommandedPharmacies(hospitalId: $hospitalId, siteType: $siteType) {
      hospitalId
      name
      prefectureCode
      addressCity
      addressStreetNumber
      addressBuilding
      tel
      postalCode
    }
  }
`;

export const UpdateMedicineDelivery = gql`
  mutation UpdateMedicineDelivery(
    $createTime: String!
    $medicineDelivery: InputMedicineDelivery!
  ) {
    updateMedicineDelivery(
      createTime: $createTime
      medicineDelivery: $medicineDelivery
    )
  }
`;

export const CancelAccount = gql`
  mutation CancelAccount {
    cancelAccount
  }
`;

export const ConsentStatus = gql`
  query ConsentStatus($hospitalId: String!, $appointmentDate: String!) {
    consentStatus(hospitalId: $hospitalId, appointmentDate: $appointmentDate) {
      consentStatus
      isSupportOnlineQualificationSystem
      onlineQualificationSystemCode
      canCancelConsent
      hasFutureAppointment
    }
  }
`;

export const GetRequiringConsentRegistration = gql`
  query ConsentStatus($hospitalId: String!, $appointmentDate: String!) {
    consentStatus(hospitalId: $hospitalId, appointmentDate: $appointmentDate) {
      isSupportOnlineQualificationSystem
      onlineQualificationSystemCode
      hasFutureAppointment
    }
  }
`;

export const CreateConsentStatus = gql`
  mutation CreateConsentStatus($createTime: String!, $hospitalId: String!) {
    createConsentStatus(createTime: $createTime, hospitalId: $hospitalId) {
      succeed
    }
  }
`;
export const UpdateConsentStatusToProgress = gql`
  mutation UpdateConsentStatusToProgress(
    $createTime: String!
    $hospitalId: String!
  ) {
    updateConsentStatusToProgress(
      createTime: $createTime
      hospitalId: $hospitalId
    ) {
      succeed
    }
  }
`;
export const UpdateConsentStatusToComplete = gql`
  mutation UpdateConsentStatusToComplete(
    $hospitalId: String!
    $externalResultCode: String!
    $externalErrorCode: String
  ) {
    updateConsentStatusToComplete(
      hospitalId: $hospitalId
      externalResultCode: $externalResultCode
      externalErrorCode: $externalErrorCode
    ) {
      succeed
    }
  }
`;
