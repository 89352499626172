import { Hub } from "aws-amplify";
import React, { useState, useContext } from "react";
import styled from "styled-components";

import { japaneseList } from "../../../Resources/japaneseList";
import {
  reopenDefaultBrowser,
  buildExternalUrlToCreate,
  createConsentStatus,
} from "../../../Utils/ConsentRegistration";
import SiteContext from "../../../SiteContext";

import ButtonFullWidth from "../../atoms/buttons/ButtonFullWidth";
import FontP from "../../atoms/fonts/FontP";
import { ConfirmationPopupWithSubText } from "../../molecules/popup/ConfirmationPopupWithSubText";

const renderResource =
  japaneseList.pages.ReservationCompletePage.render.ConsentRegistration;

const Heading2CenterCustom = styled.div`
  background-color: #ffffff;
  color: ${(p) => p.theme.color.primary};
  font-size: 18px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 8px;
`;
const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  width: 90%;
  margin: 8px auto 0px auto;
`;

interface Props {
  appointment: Appointment;
  onlineQualificationConsentStatus?: OnlineQualificationConsentStatus;
}

interface Appointment {
  createTime: number;
  hospitalId: string;
  to: string;
}
interface OnlineQualificationConsentStatus {
  isSupportOnlineQualificationSystem: string;
  onlineQualificationSystemCode: string;
  hasFutureAppointment: string;
}

export const ConsentRegistration: React.FC<Props> = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const { ui } = useContext(SiteContext);
  const { appointment, onlineQualificationConsentStatus } = props;
  if (!onlineQualificationConsentStatus) return <></>;

  const buildAppointmentDetailUrl = () => {
    const currentOrigin = window.location.origin;
    const url = new URL(currentOrigin);
    const path = `/appointment/${appointment.createTime}`;
    url.pathname = path;
    return url.toString();
  };

  const handleRegisterConsent = async () => {
    const appointmentDetailUrl = buildAppointmentDetailUrl();
    {
      const isLnln = ui === "lnln";
      if (isLnln) return reopenDefaultBrowser(appointmentDetailUrl);
    }
    const { onlineQualificationSystemCode } = onlineQualificationConsentStatus;

    // iOS13以降はユーザー操作の直後でないと新規タブを開けない
    // そのため、先に新規タブを開いておきAPIリクエストが失敗した場合は新規タブを閉じる
    const externalUrl = buildExternalUrlToCreate(
      onlineQualificationSystemCode,
      appointment.to,
      appointmentDetailUrl
    );
    const newTab = window.open(externalUrl, "_blank");
    const result = await createConsentStatus(appointment);
    if (!result) {
      if (newTab) newTab.close();
      snackBar(renderResource.Error.j001);
      return;
    }
    setIsOpen(false);
  };

  const snackBar = (message: string) => {
    Hub.dispatch(
      "msg",
      { event: "openAutoToast", data: { message: message, level: "error" } },
      "ConsentRegistration"
    );
  };

  const handleOpen = () => {
    setIsOpen(true);
  };
  const handleClose = () => {
    setIsOpen(false);
  };

  return (
    <>
      {/* 同意登録コンポーネント */}
      <Heading2CenterCustom>{renderResource.j001}</Heading2CenterCustom>
      <FontP size="s">{renderResource.j002}</FontP>
      <ButtonWrapper>
        <ButtonFullWidth onClick={handleOpen} id="confirm-consent-button">
          {renderResource.j003}
        </ButtonFullWidth>
      </ButtonWrapper>
      {/* 同意登録モーダル */}
      <ConfirmationPopupWithSubText
        isOpen={isOpen}
        handleClickConfirm={handleRegisterConsent}
        handleClosePopup={handleClose}
        confirmResource={renderResource.RegisterDialog}
        buttonGAid={{
          confirmButtonId: "register-consent-button",
          modalId: "register-consent-modal",
        }}
      />
    </>
  );
};
