import React, { useCallback } from "react";
import { withStyles } from "@material-ui/core/styles";
import Button from "../../atoms/buttons/Button";
import ButtonOutlined from "../../atoms/buttons/ButtonOutlined";
import styled from "styled-components";
import {
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
} from "@material-ui/core";

const styles = () => ({
  dialogPaper: {
    "max-height": "100%",
    "width": "300px",
  },
  dialogTitle: {
    "text-align": "center",
    "white-space": "pre-wrap",
    "padding": "30px 20px 0 20px",
  },
  dialogAction: {
    "display": "grid",
    "grid-template-columns": "1fr",
    "grid-gap": "14px",
    "padding": "0 20px 30px 20px",
    "margin": 0,
  },
  dialogContent: {
    "maxWidth": "303px",
    "padding": "15px 24px 15px 24px",
    "white-space": "pre-wrap",
  },
  dialogButton: {
    "margin": 0,
    "height": "50px",
    "font-size": "16px",
    "font-weight": "bold",
  },
});
const DialogTitleText = styled.span`
  font-size: 18px;
  color: ${(p) => p.theme.color.primary};
  font-weight: 600;
  line-height: 27px;
  text-align: center;
`;

const DialogContentText = styled.span`
  font-size: 14px;
  font-weight: 300;
  color: #393939;
  line-height: 22px;
`;

const popup = (props) => {
    const {
        classes,
        isOpen,
        handleClickConfirm,
        handleClosePopup,
        confirmResource,
        buttonGAid
    } = props;

    const handleConfirm = useCallback((e) => {
        // default event stop
        e.preventDefault();
        handleClickConfirm();
    });

    const handleClose = useCallback((e) => {
        // default event stop
        e.preventDefault();
        handleClosePopup();
    });

    return (
      <React.Fragment>
        <Dialog open={isOpen} classes={{ paper: classes.dialogPaper }}>
          <DialogTitle className={classes.dialogTitle}>
            <DialogTitleText>{confirmResource.j001}</DialogTitleText>
          </DialogTitle>
          <DialogContent className={classes.dialogContent}>
            <DialogContentText>{confirmResource.j002}</DialogContentText>
          </DialogContent>
          <DialogActions className={classes.dialogAction}>
            <Button
              className={classes.dialogButton}
              onClick={handleConfirm}
              id={buttonGAid?.confirmButtonId}
            >
              {confirmResource.j003}
            </Button>
            <ButtonOutlined
              className={classes.dialogButton}
              onClick={handleClose}
              id={buttonGAid?.closeBtnId}
            >
              {confirmResource.j004}
            </ButtonOutlined>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    );
}

export const ConfirmationPopup = withStyles(styles)(popup);