import React from "react";
import styled from "styled-components";

import ButtonFullWidth from "../../atoms/buttons/ButtonFullWidth";
import FontP from "../../atoms/fonts/FontP";

import { japaneseList } from "../../../Resources/japaneseList";

const renderResource =
  japaneseList.organisms.AppointmentDetail.AppointmentDetailContent.render
    .ConsentRegistration.Update;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
  margin-top: 10px;
`;

const SubText = styled(FontP)`
  color: ${(p) => p.theme.color.primary};
`;
interface Props {
  handleCreateClicked: () => Promise<void>;
  handleUpdateClicked: () => Promise<void>;
}
export const ConsentRegistrationFormToUpdate: React.FC<Props> = (props) => {
  const { handleCreateClicked, handleUpdateClicked } = props;
  return (
    <>
      <FontP>{renderResource.content}</FontP>
      <ButtonWrapper>
        <FontP weight="bold" color="black" size="s">
          {renderResource.createButton.upperText}
        </FontP>
        <ButtonFullWidth
          onClick={handleCreateClicked}
          id="register-consent-button"
        >
          {renderResource.createButton.title}
        </ButtonFullWidth>
        <SubText size="s">{renderResource.createButton.subText}</SubText>
      </ButtonWrapper>
      <br />
      <ButtonWrapper>
        <FontP weight="bold" color="black" size="s">
          {renderResource.updateButton.upperText}
        </FontP>
        <ButtonFullWidth
          onClick={handleUpdateClicked}
          id="update-consent-button"
        >
          {renderResource.updateButton.title}
        </ButtonFullWidth>
        <SubText size="s">{renderResource.updateButton.subText}</SubText>
      </ButtonWrapper>
    </>
  );
};
