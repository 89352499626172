import React, { useEffect } from "react";
import { Document, Page } from "react-pdf/dist/entry.webpack";
import styled from "styled-components";

const PageStyle = styled(Page)`
  width: 105px;
  height: 105px;
  border: 1px solid ${(p) => p.theme.thumbnailItem.borderColor};
  border-radius: 12px;
  background-color: ${(p) => p.theme.thumbnailItem.backgroundColor};
  display: flex;
  justify-content: center;
  align-items: center;
  & svg {
    width: inherit;
    height: inherit;
  }
  cursor: pointer;
  margin-bottom: 10px;
`;

const PdfThumbnailItem = React.memo((props) => {
  const { src } = props;
  const objectURL = URL.createObjectURL(src);
  const THUMBNAIL_WIDTH = 103;
  const FIRST_PAGE = 1;
  let pageWrapper;

  useEffect(() => {
    return () => {
      URL.revokeObjectURL(objectURL);
    };
  }, [objectURL]);

  const fitObjectContent = () => {
    const renderedElement = pageWrapper.firstElementChild;
    if (renderedElement.offsetWidth >= renderedElement.offsetHeight) {
      renderedElement.style.width = `${THUMBNAIL_WIDTH}px`;
      renderedElement.style.height = "";
    } else {
      renderedElement.style.height = `${THUMBNAIL_WIDTH}px`;
      renderedElement.style.width = "";
    }
  };

  return (
    <Document file={objectURL} loading={""}>
      <PageStyle
        pageNumber={FIRST_PAGE}
        renderMode={"svg"}
        renderTextLayer={false}
        renderAnnotationLayer={false}
        inputRef={(ref) => {
          pageWrapper = ref;
        }}
        width={THUMBNAIL_WIDTH}
        onRenderSuccess={fitObjectContent}
      />
    </Document>
  );
});

export default PdfThumbnailItem;
