import { isLnln } from "../Utils/checkLnln";

const service_name = isLnln()
  ? "ルナルナ オンライン診療"
  : "CARADA オンライン診療";
export const japaneseList = {
  service: {
    name: `${service_name}`,
  },
  insurance: {
    ok: "保険適用",
    no: "保険適用外",
  },
  menuServiceCategory: {
    examination: {
      examFeeText: "診療費",
    },
    instruction: {
      examFeeText: "診療費",
    },
    consultation: {
      examFeeText: "相談料",
    },
  },
  appointmentStatus: {
    beforeExam: {
      online: "ビデオ通話前",
      offline: "診察前",
    },
    examCompleted: {
      online: "ビデオ通話終了",
      offline: "診察完了",
    },
    // Strictly, "the amount of the bill is fixed", Payment may not be complete.
    paymentCompleted: {
      online: "ビデオ通話終了",
      offline: "診察完了",
    },
    unapproved: "承認前",
    canceledBeforeApproving: "承認前キャンセル",
    rejected: "否認済",
    canceled: "キャンセル",
  },
  comp: {
    atoms: {
      InputTextPassword: {
        j001: "表示",
      },
    },
  },
  molecules: {
    warningMessageTopPage: {
      rejectedMessage: "予約は承認されませんでした。",
      unapprovedMessage: {
        j001: "予約はまだ確定していません。",
        j002: "医師からの承認をお待ちください。",
      },
    },
    calendar: {
      Calendar: {
        renderHeader: {
          j001: "YYYY年MMM",
          Header: {
            Col: {
              NavButton: {
                j001: "前の月",
                j002: "次の月",
              },
            },
          },
        },
        renderCells: {
          TelephoneAppointmentPanel: {
            j001: "予約については、医療機関へ直接電話でお問い合わせください",
            j002: "（電話なら予約できる場合があります）",
          },
        },
      },
    },
    menu: {
      AuthenticatedMenu: {
        render: {
          MenuList: {
            MenuItem: {
              j001: "マイページ",
              j002: "利用規約",
              j003: "特定商取引法",
              j004: "個人情報の取り扱いについて",
              j005: "サイト・プライバシーポリシー",
              j006: "ヘルプ・お問い合わせ",
              j007: "ログアウト",
            },
          },
        },
      },
      UnauthenticatedMenu: {
        render: {
          MenuList: {
            MenuItem: {
              j001: "利用規約",
              j002: "特定商取引法",
              j003: "個人情報の取り扱いについて",
              j004: "ヘルプ・お問い合わせ",
            },
          },
        },
      },
      LnlnAuthenticatedMenu: {
        render: {
          MenuList: {
            MenuItem: {
              j001: `${service_name}：TOPへ`,
              j002: "お知らせ",
              j003: "マイページ",
              j004: "利用規約",
              j005: "特定商取引法",
              j006: "個人情報の取り扱いについて",
              j007: "サイトプライバシーポリシー",
              j008: "ヘルプ・お問い合わせ",
              j009: `${service_name}：ログアウト`,
            },
          },
        },
      },
      LnlnUnauthenticatedMenu: {
        render: {
          MenuList: {
            MenuItem: {
              j001: `${service_name}：TOPへ`,
              j002: "利用規約",
              j003: "特定商取引法",
              j004: "個人情報の取り扱いについて",
              j005: "ヘルプ・お問い合わせ",
            },
          },
        },
      },
      ButtonArea: {
        Button: {
          j001: "ログイン",
          j002: "会員登録",
        },
      },
    },
    BirthdateSelectForm: {
      render: {
        Root: {
          Select: {
            option: {
              j001: "年",
              j002: "月",
              j003: "日",
            },
          },
        },
      },
    },
    CancelPolicy: {
      render: {
        div: {
          Container: {
            Header: {
              j001: "キャンセルポリシー",
              j002: "診療予約のキャンセルについて",
            },
            Link: {
              j001: "メニュー説明文を確認する",
            },
            Footer: {
              j001: "※予約の開始時間を過ぎると、このページからはキャンセルができません。",
            },
          },
        },
      },
    },
    CostDetail: {
      render: {
        j001: "未定",
        j002: "円(税込)",
        j003: "円(非課税)",
        div: {
          Container: {
            CostLine: {
              Typography3: {
                j001: "システム利用料",
                j002: "円(税込)",
                j002a: "円",
                j003: "医療機関からの請求額",
                j004: "配送料（手数料含）",
                j004a: "配送料",
                j005: "円(税込)",
                j006: "処方箋料",
                j007: "円(非課税)",
                j008: "決済日",
                j009: "請求額",
              },
            },
            div: {
              CostLine: {
                Typography3: {
                  j001: "請求額",
                  j002: "その他",
                },
              },
            },
          },
          Total: {
            j001: "合計:",
            j002: "円(税込)",
            j003: "未定",
          },
          Message: {
            WarningMessage: {
              j001: "ご利用料金が確定しました。",
              j002: "（ご利用料金は、<wbr>診療後の医療機関による<wbr>金額確定時に決済され、<wbr>各クレジットカード会社の<wbr>引き落とし日に<wbr>支払われます。）",
            },
            InfoMessageBlue: {
              j001: "ご利用料金が確定しました。",
            },
          },
        },
      },
    },
    Hospital: {
      render: {
        div: {
          j001: "オンライン相談",
          j002: "オンライン診療",
          j003: "来院予約",
          j004: "オンライン診療（福利厚生）",
        },
      },
    },
    InputFormHeader: {
      export: {
        InputFormHeader: {
          WarningLabel: {
            j001: "必須",
          },
          ImportantSymbols: {
            j001: "※",
          },
        },
      },
    },
    MedicalCost: {
      render: {
        div: {
          Container: {
            CostItem: {
              CostValue: {
                j001: "円",
                j002: "受診後に確定されます。",
                j003: "契約企業によって異なります。",
                j004: "所属法人が負担します。",
                j005: "所属法人からの案内を",
                j006: "ご確認ください。",
              },
              Tax: {
                j001: " (税込)",
                j002: " (非課税)",
              },
            },
            Fragment: {
              CostItem: {
                CostName: {
                  j001: "請求額 :",
                  j002: "その他",
                },
              },
            },
          },
        },
      },
    },
    MedicalDetailAppointmentDetail: {
      render: {
        Container: {
          Title: {
            j001: "薬局名",
            j002: "医療機関名",
          },
        },
      },
    },
    RejectedCommentAppointmentDetail: {
      render: {
        Container: {
          Title: {
            j001: "否認理由",
          },
          rejectComment: {
            j001: "来院での診療が必要なため",
          },
        },
      },
    },
    MedicalMenuAppointmentDetail: {
      render: {
        Container: {
          Title: {
            j001: "診療メニュー",
          },
        },
      },
    },
    MenuTitle: {
      render: {
        div: {
          Container: {
            Title: {
              Text: {
                j001: "先生",
              },
            },
          },
          Dialog: {
            DialogActions: {
              ButtonContainer: {
                FullWidthButton: {
                  j001: "閉じる",
                },
              },
            },
          },
        },
      },
    },
    PaymentDetailAppointmentDetail: {
      render: {
        div: {
          Container: {
            Header: {
              j001: "ご利用料金",
            },
          },
          Message: {
            BillingEnterprise: {
              j001: "所属法人からの案内をご確認ください",
            },
          },
        },
      },
    },
    MedicineDeliveryDetail: {
      render: {
        div: {
          Container: {
            Header: {
              j001: "お薬の説明と受取方法の選択",
            },
            ButtonContainer: {
              j001: "変更",
            },
          },
          Message: {
            j001: "登録した住所に処方せんを配送してもらい、自分で薬局に薬を取りに行く​",
            j002: "オンラインでお薬の説明を聞いて、登録した住所にお薬を配送してもらう​",
            j003: "処方せんは以下の住所に配送されます。",
            j004: "ここで薬局の予約はできません",
            j005: "処方せんは薬局へお持ちいただき、薬剤師によるお薬の説明をうけ、お薬を受け取ってください。",
            j006: "オンライン診療の結果、お薬の説明をオンラインで受ける場合、",
            j007: "未選択",
            j008: "※処方せんの有効期限は原則、診療日から4日間（薬局の休業日含む）です。お早めに薬局を予約してください。\n※処方せん・お薬の配送料はご登録のクレジットカードにご請求いたします。\n※オンライン診療の結果、お薬の処方がない場合やご希望の受取方法から変更になる場合があります。",
            j009: "現在こちらの薬局は、オンラインでお薬の説明を受けることができません。",
            j010: "ご自身で病院/クリニックと合意した薬局の予約をしてください。\n",
            j011: "オンラインで薬剤師からお薬の説明を受けた後、薬局からご登録の住所にお薬が配送されます。",
          },
        },
      },
    },
    PaymentDetailConfirmPage: {
      render: {
        div: {
          Container: {
            Header: {
              j001: "ご利用料金",
            },
            ContainerFee: {
              CostLine: {
                Typography3: {
                  j003: "請求額",
                  j004: "未定",
                },
              },
            },
            Total: {
              j001: "合計: ",
              j002: "未定",
            },
            Message: {
              WarningMessage: {
                j001: "（ご利用料金は、<wbr>診療後の医療機関による<wbr>金額確定時に決済され、<wbr>各クレジットカード会社の<wbr>引き落とし日に<wbr>支払われます。）",
              },
            },
          },
        },
      },
    },
    RegisterMail: {
      render: {
        Container: {
          Content: {
            j001: "メールアドレスをご登録していただけると、",
            j002: "診察前にお知らせメールが届きます。",
          },
          ButtonContainer: {
            FullWidthButton: {
              j001: "メールアドレス登録へ",
            },
          },
        },
      },
    },
    TimeStatusAppointmentDetail: {
      render: {
        div: {
          Container: {
            Title: {
              j001: "予約日時",
            },
            Detail: {
              j001: "の枠"
            },
          },
        },
      },
    },
    FileUploadDetail: {
      title: "医療機関からの共有ファイル",
      caution:
        "ファイルを閲覧できる期間はファイルがアップされてから1週間です。表示期間を過ぎたファイルは自動的に削除されますのでご注意ください。",
      period: "表示期間",
      deleteByDoctor: "医療機関の都合により削除されました。",
      deleteByPeriod: "表示期間が過ぎたため削除されました。",
      note: {
        j001: "【注意事項】",
        j002: "＜ファイルの表示期間＞ <br /> ​ファイル登録完了時から7日間 <br /> ※ファイルは表示期間を経過すると自動で削除されます。​",
      },
    },
    Appointment: {
      textAppointmentDateSuffix: "の枠",
      textRejected: " 承認されませんでした",
      textUnapproved: " 医師の承認待ちです",
      textConsentUnregistered: " オンライン資格確認の同意可否が未選択です",
    },
    Popup: {
      ConfirmPopup: {
        j001: "本当に削除しますか？",
        j002: "削除する",
        j003: "戻る",
      },
      IVRConfirmPopup: {
        j001: "認証コードを \n自動音声にてお知らせします",
        j002: "発信者番号が非表示になる場合や、ご契約のプランによっては着信料金がかかる場合があります。",
        j003: "受け取る",
        j004: "戻る",
      },
      CancelMemberConfirmPopup: {
        j001: "退会の確認",
        j002: "本当に退会しますか？",
        j003: "退会する",
        j004: "閉じる",
      },
      CancelMemberInformationPopup: {
        j001: "退会の確認",
        j002: "退会が完了しました。\nご利用ありがとうございました。",
        j003: "閉じる",
      },
    },
    PatientUploadFile: {
      thumbnailList: {
        j001: "画像を追加",
      },
    },
    account: {
      IrregularAppointment: {
        render: {
          Description: {
            j001: "予約内容",
            j002: "予約日時",
            j003: "医療機関名",
            j004: "メニュー",
            j005: "決済日",
            j006: "退会可能日",
          },
          DateTimeFormat: {
            j001: "YYYY[年]MM[月]DD[日]（dd） HH:mm",
            j002: "HH:mmの枠",
            j003: "YYYY[年]MM[月]DD[日]（dd）",
          },
        },
      },
    },
  },
  organisms: {
    auth: {
      AuthPanelCaution: {
        text: "「※」がついている入力項目は、ご登録後の変更ができません。\nお間違えのないようご確認の上ご登録ください。",
      },
      InputNameForm: {
        Title: "お名前（全角）",
        FamilyName: {
          title: "姓",
          placeholder: "山田",
        },
        GivenName: {
          title: "名",
          placeholder: "太郎",
        },
      },
      InputKanaForm: {
        Title: "お名前フリガナ（全角カナ）",
        FamilyName: {
          title: "セイ",
          placeholder: "ヤマダ",
        },
        GivenName: {
          title: "メイ",
          placeholder: "タロウ",
        },
      },
      RadioSex: {
        Title: "性別",
        Radio: {
          j001: "男性",
          j002: "女性",
        },
      },
      SelectBirthDate: {
        Title: "生年月日",
      },
      AddressArea: {
        render: {
          div: {
            Title: "住所",
            note: {
              text: {
                j001: "入力内容が誤っている場合、薬・処方箋が受け取れない恐れがございます。また、ご自宅住所の登録を推奨いたします。ご自宅以外の登録に際して生じたトラブルについては、当社は一切責任を負いかねます。",
              },
            },
            Fragment: {
              PostalCodeRow: {
                Label: "郵便番号",
                InputText: {
                  j001: "例:7950013",
                },
                Button: {
                  j001: "住所検索",
                },
              },
              Row: {
                FullWidth: {
                  Select: {
                    Label: "都道府県",
                    j001: "都道府県",
                  },
                },
                InputText: {
                  AddressCity: {
                    Label: "市区町村",
                    Placeholder: "全角70文字以内",
                  },
                  addressStreetNumber: {
                    Label: "番地",
                    Placeholder: "全角15文字以内",
                  },
                  addressBuilding: {
                    Label: "建物名・部屋番号",
                    Placeholder: "全角30文字以内",
                  },
                },
              },
            },
          },
        },
      },
      InputTelForm: {
        Title: "お電話番号（半角数字）",
        placeholder: "例：090000000",
        note: {
          text: {
            j001: "本人確認のため、SMS（ショートメッセージサービス）を受信できる携帯電話番号を登録してください。",
            j002: "なお、固定電話・IP電話の番号は登録できません。",
          },
        },
      },
      InputMailForm: {
        Title: "メールアドレス",
        placeholder: "XXXXXX@XXX.com",
      },
      InputPasswordForm: {
        Title: "パスワード（半角英数字）",
        text: "確認のためもう一度入力してください。",
        placeholder: "大文字と小文字を含めて８文字以上",
        note: {
          text: {
            j001: "セキュリティ確保のため、パスワードは",
            j002: "大文字と小文字を含む8文字以上の英数字",
            j003: "で登録してください。",
            j004: "また、以下の内容を避けてください。",
            j005: "・他人に推測されやすい名前や生年月日のみのもの",
            j006: "・他サービスで利用されているもの",
          },
        },
      },
      SignInLnlnCaution: {
        text: {
          j001: `「${service_name}」をご利用いただくには、ルナルナIDとは別の専用アカウントが必要になります。\n「${service_name}」のアカウントをお持ちでない方は、`,
          j002: "会員登録",
          j003: "よりご登録ください。",
        },
      },
    },
    onlineChat: {
      OnlineChat: {
        WaitText: {
          j001: "ただいま接続中です。",
          j002: "このままお待ちください。",
          j003: "しばらく待っても通話が始まらない場合は",
          j004: "ページを再読み込みしてください。",
        },
        WarningMessage: {
          j001: "※ビデオ通話はご自身で終了することができません。終了したい場合は通話先の方にお伝えください。",
        },
        NetworkQualityWarning: {
          j001: "インターネット接続が不安定です。",
          j002: "Wi-Fi環境に切り替える、または、電波が良い場所に移動してください。",
        },
        AudioLowWarning: {
          j001: "マイクの入力音量が小さい、または、ミュートになっています。",
        },
      },
      OnlineChatCautionButtons: {
        export: {
          DialogActions: {
            Button: {
              j001: "ビデオ通話画面へ",
            },
          },
        },
      },
      OnlineChatCautionDialog: {
        export: {
          Dialog: {
            Header: {
              j001: "接続中です。",
              j002: "そのままお待ちください。",
            },
            Body: {
              InfoMessage: {
                Typography2: {
                  j001: "通信環境により繋がるまでに時間がかかる場合がありますが、そのままお待ちいただければ通話が開始されます。",
                },
              },
            },
          },
        },
      },
      OnlineChatDialog: {
        export: {
          Dialog: {
            OnlineChatDialogHeader: {
              j001: "このページから移動してもよろしいでしょうか？",
            },
            OnlineChatDialogBody: {
              WarningMessage: {
                Typography2: {
                  j001: "別のページに移動するとビデオ通話が中断します。",
                },
              },
              InfoMessage: {
                Typography2: {
                  j001: "ビデオ通話を終了されたい場合は、必ず通話相手にお伝えください。",
                },
              },
            },
          },
        },
      },
      OnlineChatDialogButtons: {
        export: {
          DialogActions: {
            DialogButton: {
              j001: "キャンセル",
              j002: "移動する",
            },
          },
        },
      },
    },
    onlineChatFinishHospital: {
      h1: "ビデオ通話終了",
      Appointment: {
        button: "予約内容",
        text: "（マイページの診療履歴からもご確認頂けます）",
      },
      contactUs: {
        button: "お問い合わせ",
        text: "（メーラーが立ち上がります）",
      },
    },
    payment: {
      PaymentCardInfo: {
        render: {
          Fragment: {
            Row: {
              Typography: {
                j001: "カード番号",
                j002: "有効期限",
              },
            },
            FullWidthButton: {
              j001: "更新する",
            },
          },
        },
      },
      PaymentCardUpdate: {
        onRegisterCardError: {
          j001: "カード登録エラーが発生しました : ",
        },
        onGetToken: {
          j001: "ご指定のカードはご利用いただけませんでした。カード会社にお問い合わせの上、再度クレジット情報をご入力下さい。",
        },
        render: {
          Desc: {
            j001: "ご利用可能なカード",
          },
          TitleText: {
            j001: "カード番号",
            j002: "有効期限 (月/年)",
            j003: "セキュリティコード",
          },
          Placeholder: {
            j001: "",
            j002: "",
            j003: "",
          },
          Question: {
            j001: "セキュリティコードとは？",
          },
          Button: {
            j001: "更新する",
            j002: "登録する",
          },
        },
      },
      PaymentDialog: {
        render: {
          Dialog: {
            DialogTitle: {
              Title: {
                j001: "セキュリティコードとは？",
              },
            },
            DialogContent: {
              DialogContentText: {
                j001: "クレジットカード裏面の署名欄に印字されている３桁の数字です。",
              },
            },
            DialogActions: {
              CloseButton: {
                j001: "閉じる",
              },
            },
          },
        },
      },
      PaymentErrorList: {
        j001: "トークン取得正常終了",
        j002: "カード番号必須チェックエラー",
        j003: "カード番号フォーマットエラー(数字以外を含む)",
        j004: "カード番号フォーマットエラー(10-16 桁の範囲外)",
        j005: "有効期限必須チェックエラー",
        j006: "有効期限フォーマットエラー(数字以外を含む)",
        j007: "有効期限フォーマットエラー(6 又は4 桁以外)",
        j008: "有効期限フォーマットエラー(月が13 以上)",
        j009: "セキュリティコードフォーマットエラー(数字以外を含む)",
        j010: "セキュリティコード桁数エラー",
        j011: "名義人フォーマットエラー(半角英数字、一部の記号以外を含む)",
        j012: "名義人フォーマットエラー(51 桁以上)",
        j013: "発行数フォーマットエラー(数字以外を含む)",
        j014: "発行数フォーマットエラー(1-10 の範囲外)",
        j015: "カード情報を暗号化した情報必須チェックエラー",
        j016: "ショップID 必須チェックエラー",
        j017: "ショップID フォーマットエラー(14 桁以上)",
        j018: "ショップID フォーマットエラー(半角英数字以外)",
        j019: "公開鍵ハッシュ値必須チェックエラー",
        j020: "ショップID または公開鍵ハッシュ値がマスターに存在しない",
        j021: "カード情報(Encrypted)が復号できない",
        j022: "カード情報(Encrypted)復号化後フォーマットエラー",
        j023: "トークン用パラメータ(id)が送信されていない",
        j024: "トークン用パラメータ(id)がマスターに存在しない",
        j025: "トークン用パラメータ(cardInfo)が送信されていない",
        j026: "トークン用パラメータ(cardInfo)が復号できない",
        j027: "トークン用パラメータ(key)が送信されていない",
        j028: "トークン用パラメータ(key)が復号できない",
        j029: "トークン用パラメータ(callBack)が送信されていない",
        j030: "トークン用パラメータ(hash)が存在しない",
        j031: "トークン用apikey が存在しないID",
        j032: "トークン用apikey が有効ではない",
        j033: "マルチペイメント内部のシステムエラー",
      },
      RecaptchaErrorList: {
        j001: `予期せぬエラーが発生しました。メニューより「ヘルプ・問い合わせ」を押下し、ページの下部にある「${service_name}をご利用の方」より、お問い合わせください。`,
        j002: "予期せぬエラーが発生しました。操作をやり直してください。",
        onNetworkError: {
          j001: "予期せぬエラーが発生しました。操作をやり直してください。",
        },
      },
    },
    profile: {
      ProfileUpdateForm: {
        validateInput: {
          j001: "※未入力の項目があります。",
          j002: "※入力内容をご確認ください。",
          j003: "使用できない文字が入力されています。「お名前（姓）」を修正してください。",
          j004: "使用できない文字が入力されています。「お名前（名）」を修正してください。",
          j005: "使用できない文字が入力されています。「フリガナ（セイ）」を修正してください。",
          j006: "使用できない文字が入力されています。「フリガナ（メイ）」を修正してください。",
          j007: "「お名前（姓）」は、全角で入力してください。",
          j008: "「お名前（名）」は、全角で入力してください。",
          j009: "「フリガナ（セイ）」は、全角で入力してください。",
          j010: "「フリガナ（メイ）」は、全角で入力してください。",
          j011: "「フリガナ（セイ）」は、カタカナで入力してください。",
          j012: "「フリガナ（メイ）」は、カタカナで入力してください。",
        },
        render: {
          j001: "会員ID：",
          j002: `${service_name}`,
          j003: "メールの件名、及び、上記の情報は変更しないでください。",
          j004: "お問い合わせ内容を以下にご記入ください",
          div: {
            PrimaryHeading: {
              j001: "会員情報",
            },
            Panel: {
              ParagraphCenter: {
                j001: "会員情報の変更が完了しました",
              },
            },
            BottomActionPanel: {
              FullWidthButton: {
                j001: "マイページに戻る",
              },
            },
          },
          Fragment: {
            div: {
              div: {
                PrimaryHeading: {
                  j001: "会員情報",
                },
                InputFormHeader: {
                  j001: "お名前（全角）",
                  j002: "フリガナ（全角カタカナ）",
                  j003: "性別",
                  j004: "生年月日",
                  j005: "お電話番号",
                  j006: "メールアドレス",
                  j007: "従業員番号",
                },
                NamedInputFormArea: {
                  NamedInputDevided: {
                    NamedInputText: {
                      j001: "10文字以内",
                      j002: "姓",
                      j003: "名",
                      j004: "セイ",
                      j005: "メイ",
                    },
                  },
                },
                FormArea: {
                  RadioGroup: {
                    Radio: {
                      j001: "男性",
                      j002: "女性",
                    },
                  },
                },
                UpdateButtonPanel: {
                  FullWidthButton: {
                    j001: "登録内容を変更する",
                  },
                },
                ContactParagraph: {
                  j001: "※編集ができない項目を変更したい場合は、",
                  j002: "からお問い合わせください。",
                  LinkWithoutUnderline: {
                    j001: "こちら",
                  },
                },
              },
            },
            NavigateButton: {
              j001: "マイページ",
            },
          },
        },
      },
    },
    reservationForm: {
      ReservationConfirmForm: {
        onSubmit: {
          j001: "※キャンセルポリシーに同意して下さい。",
          j002: "この予約枠は削除された可能性があります。予約日時を変更してください。",
          j003: "過去の日時が指定されています。予約日時を変更してください。",
          j004: "指定の日時はすでに予約が入っています。予約日時を変更してください。",
          j005: "予期せぬエラーが発生しました。TOPページに戻り、はじめからやり直してください。",
          j006: "※予約データが存在します。再度予約時間を選択して下さい。",
          j007: "指定の日時はシステムメンテナンスが予定されています。予約日時を変更してください。",
          j008: "この予約枠は削除された可能性があります。TOPページに戻り、はじめからやり直してください。",
          j009: "指定の日時は別な医療機関との予約が入っています。予約日時を変更してください。",
          j010: "予約の受付時間が過ぎました。予約日時を変更してください。",
          j011: "この予約枠は削除された可能性があります。TOPページに戻り、はじめからやり直してください。",
        },
        render: {
          Fragment: {
            PrimaryHeading: {
              j001: "予約内容確認",
            },
            InputFormHeaderNoIcon: {
              j001: "こちらの内容で予約しますか？",
            },
            Container: {
              Title: {
                j001: "医療機関名",
                j002: "メニュー",
                j003: "メニュー",
                j004: "予約日時",
                j005: "ご利用料金",
              },
              Detail: {
                j001: "の枠",
                j002: "所属法人が負担します​",
                j003: "所属法人からの案内をご確認ください",
              },
              div: {
                Title: {
                  j001: "相談内容",
                  j001a: "企業名",
                },
              },
              Consumer: {
                Fragment: {
                  Fragment: {
                    Title: {
                      j001: "通知",
                    },
                    Detail: {
                      j001: "希望する",
                      j002: "希望しない",
                      j003: "メール",
                      j004: "希望する",
                      j005: "希望しない",
                    },
                  },
                },
              },
              ButtonContainer: {
                FullWidthButton: {
                  j001: "この内容で予約する",
                },
                FullWidthButtonSub: {
                  j001: "日時選択に戻る",
                  j002: "戻る",
                },
              },
            },
            AttentionSection: {
              Title: { j001: "注意事項" },
              Detail: {
                BeforeText: { j001: "・" },
                j001: "医療機関が予約を承認するまでは、予約が確定しません。",
                j002: "オンライン診療の予約は、医師が「オンライン診療の実施が可能」と判断した方のみが受診できるメニューです。こちらに当てはまらない場合、予約は承認されません。​",
              },
            },
            div: {
              CheckBoxContainer: {
                CheckboxLabel: {
                  j001: "キャンセルポリシーに同意する",
                  j002: "上記の内容に同意する",
                },
              },
            },
            InterviewSheetAnswerDetail: {
              Title: {
                j001: "問診内容",
              },
              Preface: {
                j001: "予約完了後、ビデオ通話開始1時間前までは、予約詳細画面より問診内容を編集することができます。",
              },
            },
            ThumbnailList: {
              Title: {
                j001: "画像",
              },
            },
            MedicineDelivery: {
              Title: {
                j001: "お薬の説明と受取方法の選択",
              },
              DeliveryMethod: {
                j001: "オンラインでお薬の説明を聞いて、登録した住所にお薬を配送してもらう",
                j002: "登録した住所に処方せんを配送してもらい、自分で薬局に薬を取りに行く",
                j003: "※処方せん・お薬の配送料はご登録のクレジットカードにご請求いたします。\n※オンライン診療の結果、お薬の処方がない場合やご希望の受取方法から変更になる場合があります。",
                j004: "オンライン診療の結果、お薬の説明をオンラインで受ける場合、",
                j005: "ご自身で病院/クリニックと合意した薬局の予約をしてください。\n",
                j006: "オンラインで薬剤師からお薬の説明を受けた後、薬局からご登録の住所にお薬が配送されます。",
              },
              Message: {
                j001: "ここで薬局の予約はできません",
                j002: "処方せんは以下の住所に配送されます。\n処方せんは薬局へお持ちいただき、薬剤師によるお薬の説明をうけ、お薬を受け取ってください。",
                j003: "※オンライン診療後、ご自身で薬局を予約してください。",
              },
            },
          },
        },
      },
      ReservationForm: {
        checkInput: {
          j001: "※入力内容をご確認下さい",
          j002: "※相談内容が100文字を超えています。",
          j002a: "※企業名が25文字を超えています。",
        },
        onSubmit: {
          j001: "この予約枠は削除された可能性があります。予約日時を変更してください。",
          j002: "この予約枠は削除された可能性があります。TOPページに戻り、はじめからやり直してください。",
          j003: "過去の日時が指定されています。予約日時を変更してください。",
          j004: "指定の日時はすでに予約が入っています。予約日時を変更してください。",
          j005: "指定の日時は別な医療機関との予約が入っています。予約日時を変更してください。",
          j006: "指定の日時はシステムメンテナンスが予定されています。予約日時を変更してください。",
          j007: "予期せぬエラーが発生しました。TOPページに戻り、はじめからやり直してください。",
          j008: "この予約枠は削除された可能性があります。TOPページに戻り、はじめからやり直してください。",
        },
        RenderDialog: {
          Dialog: {
            DialogTitle: {
              j001: "ご利用にはアカウントが必要です",
            },
            DialogContent: {
              DialogContentText: {
                j001: "アカウントをお持ちでない方は、会員登録へお進みください。",
              },
              LnlnDiaLogContentText: {
                j001: "ご利用にはルナルナIDとは別の専用アカウントが必要になります。\n「ルナルナ オンライン診療」のアカウントをお持ちでない方は、会員登録へお進みください。",
              },
            },
            DialogActions: {
              ButtonSubmit: {
                j001: "会員登録",
                j002: "ログイン",
                j003: "戻る",
              },
            },
          },
        },
        ReconfirmTelephoneAppointmentDialog: {
          Dialog: {
            DialogTitle: {
              j001: "希望日時に\n予約できませんでしたか？",
            },
            DialogContent: {
              DialogContentText: {
                j001: "希望する予約枠がなくても、医療機関へ直接電話することで予約できる場合があります",
              },
            },
            DialogActions: {
              ButtonSubmit: {
                j001: "電話をかける",
                j002: "戻る",
              },
            },
          },
        },
        RegisterPassingDialog: {
          Dialog: {
            DialogTitle: {
              j001: "予約にはクレジットカード、\n住所の登録が必要です",
              j002: "予約には住所の登録が必要です",
              j003: "予約にはクレジットカードの登録が必要です",
            },
            DialogContent: {
              DialogContentText: {
                j001: "マイページからクレジットカード情報、住所情報を登録してください",
                j002: "マイページから住所情報を登録してください",
                j003: "マイページからクレジットカード情報を登録してください",
              },
            },
            DialogActions: {
              ButtonSubmit: {
                j001: "必要情報を登録する",
                j002: "戻る",
              },
            },
          },
        },
        RenderSelectForm: {
          Fragment: {
            FormDevided: {
              InputFormTitle: {
                j001: "時間枠を選択",
              },
              FormGroup: {
                TimeBox: {
                  SecondaryInfoMessage: {
                    j001: "※時間枠のご予約になりますので、開始時刻が時間枠の開始時間ではございません。ご注意ください。",
                  },
                },
              },
            },
            TelephoneAppointment: {
              FormDevided: {
                FormGroup: {
                  Message: {
                    j001: "希望する予約枠がなくても、医療機関へ直接電話することで予約できる場合があります。",
                  },
                  Button: {
                    j001: "電話で予約する",
                  },
                },
              },
            },
            Fragment: {
              InputFormTitle: {
                j001: "相談内容",
                j001a: "企業名",
              },
              FormGroup: {
                InputFormHeaderNote: {
                  j001: "ご相談内容を入力してください。（100文字以内）",
                  j001a: "(25字以内)",
                },
                Margin: {
                  InputTextarea: {
                    j001: "ご相談内容を入力して下さい。",
                  },
                },
                Content: {
                  j001: "ご予約の診療時間が近づきましたらご登録のメールアドレスまたは電話番号へお知らせいたします。ご希望の通知方法にチェックを入れてください。",
                },
                CheckboxContainer: {
                  CheckboxLabel: {
                    CheckboxSMSLabel: {
                      j001: "メール通知を希望する",
                      j002: "SMS通知を希望する",
                    },
                  },
                },
                NotificationSettingsNote: {
                  MainMessage: {
                    j001: "ご予約の時間が近づきましたら、",
                    j002: "お知らせいたします。",
                    BoldMessage: {
                      j001: "ご登録の電話番号へSMS通知にて",
                      j002: "ご登録のメールアドレス宛に",
                    },
                  },
                  SecondaryInfoMessage: {
                    j001: "（通知をメールアドレスで受け取りたい場合は、予約完了画面でご登録できます。）",
                  },
                },
              },
              InputFormHeaderNoIcon: {
                j001: "お知らせ通知設定",
              },
            },
          },
        },
        render: {
          Container: {
            Fragment: {
              PrimaryHeading: {
                j001: "来院予約",
                j002: "予約：",
                j003: "先生",
              },
              InputFormHeaderVariant2: {
                j001: "予約日時選択",
              },
              DateRangeIconHeading: {
                j001: "ご希望の日付を選択してください。",
              },
              InputFormTitle: {
                j001: "予約日を選択",
              },
            },
            FormDevided: {
              Panel: {
                WarningMessage: {
                  j001: "予約可能な時間枠がありません。",
                  j002: "別の日付をご確認ください。",
                },
              },
              FormGroup: {
                CheckEntriesButton: {
                  j001: "次へ",
                },
                FullWidthButtonSub: {
                  j001: "戻る",
                },
              },
            },
            TelephoneAppointment: {
              FullWidthButton: {
                j001: "電話で予約する",
              },
              FullWidthOutlinedButton: {
                j001: "電話で予約する",
              },
              FontPCenter: {
                j001: "希望する予約枠がなくても、医療機関へ直接電話することで予約できる場合があります",
              },
            },
          },
        },
      },
      InterviewSheetForm: {
        FormGroup: {
          ConfirmButton: {
            j001: "次へ",
          },
          RevertButton: {
            j001: "戻る",
          },
        },
      },
      StepperForm: {
        j001: "日時選択",
        j002: "問診記入",
        j003: "画像共有",
        j004: "確認",
        j005: "完了",
        j006: "お薬",
      },
      UploadFilePopup: {
        title: "画像共有時の注意点",
        description:
          "撮影した画像を共有する場合は、撮影対象が大きくはっきりと写っている画像を共有してください。",
        lnlnCaution1:
          "※ルナルナアプリ内からご利用の方は、ここからカメラを起動できません。",
        lnlnCaution2: "予め撮影のうえ、保存済みの画像から選択してください。",
        lnlnCautionAndroidRegisterApp1:
          "※ルナルナアプリ内からご利用の方は、ここから画像を共有できません。",
        lnlnCautionAndroidRegisterApp2:
          "予約後、Chromeブラウザを起動して、予約詳細ページより画像を共有してください。",
        lnlnCautionAndroidDetailApp1:
          "※ルナルナアプリ内からご利用の方は、ここから画像を共有できません。",
        lnlnCautionAndroidDetailApp2:
          "Chromeブラウザを起動して、予約詳細ページより画像を共有してください。",
        uploadButton: "画像を共有する",
        cancelButton: "戻る",
      },
      ReservationUploadFileForm: {
        j001: "予約する医療機関へ保険証以外の画像を共有することができます。事前に医療機関から指示がある場合にご利用ください。",
        j002: "予約完了後も、「予約詳細」より画像の追加・削除が可能です。",
        j003: "画像を共有する",
        j004: "共有せずに次へ",
        j005: "戻る",
      },
      ReservationUploadFileNote: {
        j001: "【画像の注意事項】",
        j002: "＜共有できる画像＞ <br /> ​​.png・.jpg形式の画像を5枚まで ​ <br />",
        j003: "＜共有画像の保存期間＞  <br /> ​選択した予約日の翌月末日まで<br />​※保存期間を経過すると自動で削除されます。",
      },
      ReservationUploadFileReview: {
        j001: "こちらの画像を共有しますか？",
        j002: "画像名",
        j003: "画像を変更する",
        j004: "登録する",
        j005: "戻る",
        // Handle error:  If photo name is not entered
        j006: "画像名が未入力です。30文字以内で入力してください。",
        // Handle error: If Photo name is more than 31 characters
        j007: "画像名が長すぎます。30文字以内で入力してください。",
        // Handle error: If photo name includes unusual characters
        j008: '画像名に特殊文字や次の半角記号（¥ / : * ? " < > |.）は使用できません。',
        // Check expired upload file error if photo upload deadline had passed
        j009: "共有できる期間を過ぎているため、画像の追加はできません。",
        // System error occurs when upload file
        j010: "アップロード中にエラーが発生しました。時間をおいて再度お試しください。",
        // Error if booking had been rejected
        j011: "予約が否認されたため、画像の追加はできません。",
        // Error if booking had been canceled
        j012: "予約がキャンセルされたため、画像の追加はできません。",
      },
      ReservationUploadFileThumbnailList: {
        j001: "を追加しました。",
        j002: "次へ",
        j003: "戻る",
        j004: "の変更を保存しました。",
        j005: "を削除しました。​",
      },
      ReservationUploadFileDetail: {
        j001: "変更を保存する",
        j002: "削除する",
        j003: "戻る",
        j004: "表示期間",
        j005: "まで",
        // System error occurs when upload file
        j006: "アップロード中にエラーが発生しました。時間をおいて再度お試しください。",
        // System error occurs when update and delete file
        j007: "エラーが発生しました。時間をおいて再度お試しください。",
        // Handle error:  If photo name is not entered
        j008: "画像名が未入力です。30文字以内で入力してください。",
        // Handle error: If Photo name is more than 31 characters
        j009: "画像名が長すぎます。30文字以内で入力してください。",
        // Handle error: If photo name includes unusual characters
        j010: '画像名に特殊文字や次の半角記号（¥ / : * ? " < > |.）は使用できません。',
        // Handle error: If this file had already been deleted,
        j011: "この画像は削除されました。",
      },
    },
    AddressDetail: {
      render: {
        PrimaryHeading: {
          j001: "住所情報",
        },
        Fragment: {
          Row: {
            Typography: {
              j001: "郵便番号",
              j002: "都道府県",
              j003: "市区町村",
              j004: "番地",
              j005: "建物名・部屋番号",
            },
            FullWidthButton: {
              j001: "更新する",
            },
          },
        },
        Row: {
          MarginWrapper: {
            Typography: {
              j001: "住所が登録されていません。",
            },
          },
          FullWidthButton: {
            j001: "住所を登録する",
          },
        },
      },
    },
    AddressForm: {
      render: {
        div: {
          PrimaryHeading: {
            j001: "住所更新",
            j002: "住所登録",
          },
          Fragment: {
            PostalCodeRow: {
              InputText: {
                j001: "郵便番号（例:7950013)",
              },
              Button: {
                j001: "住所検索",
              },
            },
            Row: {
              FullWidth: {
                Select: {
                  j001: "都道府県",
                },
              },
              InputText: {
                j001: "市区町村（※全角70文字以内）",
                j002: "番地（※全角15文字以内）",
                j003: "建物名・部屋番号（※全角30文字以内）",
              },
              FullWidthButton: {
                j001: "更新する",
                j002: "登録する",
              },
              WarningMessage: {
                j001: "※入力内容が誤っている場合、薬・処方箋が受け取れない恐れがございます。また、ご自宅住所の登録を推奨致します。ご自宅以外の登録に際して生じたトラブルについては、当社は一切責任を負いかねます。",
              },
            },
            Fragment: {
              Row: {
                Agreement: {
                  j001: "当社は、お客様の氏名、配送先住所、電話番号、処方内容等の情報を、お客様への処方せん又は処方薬の送付及び配送に関連する業務の目的で利用いたします。",
                  j002: "当社は、当該送付及び配送に必要な範囲で、登録医療機関又は登録医師及びヤマト運輸株式会社に対して、お客様の氏名、配送先住所、電話番号、処方内容等の情報を提供いたします。",
                },
                CheckboxLabel: {
                  j001: "上記事項に同意して住所を登録する。",
                },
              },
            },
          },
        },
      },
    },
    AppointmentHistoryPage: {
      render: {
        j001: "もっと見る",
        j002: "現在こちらの病院/クリニックは、予約できません。",
        j003: "現在こちらの薬局は、予約できません。",
        j007: "の枠",
        j008: "予約履歴から探す（病院/クリニック）",
        j009: "予約履歴から探す（薬局）",
      },
    },
    ApplicationPrivacyPolicy: {
      render: {
        j001: "会員ID：",
        j002: `${service_name}`,
        j003: "メールの件名、及び、上記の情報は変更しないでください。",
        j004: "お問い合わせ内容を以下にご記入ください",
        Container: {
          div: {
            PrimaryHeading: {
              j001: "サイト・プライバシーポリシー",
            },
            SecondaryHeading: {
              j001: "１.本サイト本体から送信される利用者情報、目的、送信先等について",
              j002: "2.情報収集モジュールから送信される利用者情報、目的、送信先等について",
            },
            Paragraph: {
              j001: "【送信される情報】",
              j002: "・電話番号をサービス機能利用（サービスログインの為）のために、株式会社カラダメディカへユーザーにより手動送信します。",
              j003: "・本サイトで生成するID（UUID)をサービス機能利用（問い合わせ対応）のために、株式会社エムティーアイへユーザーにより手動送信します。",
              j004: "・利用端末情報（機種名／OSバージョンなど）をサービス機能利用（問い合わせ対応）のために、株式会社エムティーアイへユーザーにより手動送信します。",
              j007: "【第三者提供の有無】",
              j008: "当社に送信される情報のうち、以下についてはお客様個人が特定されない統計データとして第三者提供される場合があります。",
              j010: "・本サイトの画面閲覧や機能利用の履歴情報をマーケティング及びサービス利用動向の統計分析・サービス反映のためのために、株式会社エムティーアイへ提供される場合があります。",
              j011: "【情報送信の停止手段・影響など】",
              j012: "送信される情報を停止する手段はありません。やむを得ず送信停止をご希望される場合は、本サイトのご利用を中止してください。",

              j013: "本サイトでは、以下に記載の情報収集モジュールを利用しています。",
              j014: "※情報収集モジュールとは、サイトの利用状況等の統計データ収集やサイト内での広告表示等を行うために、サイト本体の機能とは独立した、第三者が作成したプログラムのことです。",
              j015: "【送信される情報】",
              j016: "本サイトではGoogle LLCのアクセス解析ツール「Google Analytics」を利用しており、以下の情報がお客様個人が特定されない統計データとして送信されます。",
              j017: "・本サイトのご利用状況（本サイト訪問回数・滞在時間、閲覧ページ数・画面遷移等）",
              j018: "・ご利用端末の情報（端末の設定言語・地域、機種名、OS・ブラウザバージョン、プロバイダ等）",
              j019: "【利用目的】",
              j020: "送信された情報は、本サイトの機能評価・改善およびマーケティング分析のために当社で利用します。",
              j021: "【送信方法・送信先】",
              j022: "Google LLCへ本情報収集モジュールが自動送信します。",
              j023: "【第三者提供の有無】",
              j024: "Google Analyticsを通じて当社が取得した情報は、個人識別性を有するデータおよびお客様個人が特定されない統計データとして以下に記載の第三者に提供される場合があります。",
              j025: "・マーケティング及びサービス利用動向の統計分析・サービス反映のため、株式会社エムティーアイへ提供される場合があります。",
              j026: "【情報収集モジュール提供会社のプライバシーポリシー】",
              j027: "【情報送信の停止手段・影響など】",
              j028: "情報送信の停止をご希望される場合、Google LLCが提供するオプトアウト機能を用いて情報送信停止の操作をお客様ご自身で行ってください。",
              j029: "※情報送信を停止した場合でも、本サイトのサービス機能は制約なくご利用いただけます。",
              j030: `本サイト・プライバシーポリシーは、株式会社カラダメディカ（以下、『当社』という）がスマートフォン等に向けて提供する『ルナルナ/CARADA オンライン診療』サイト（以下、『本サイト』という）から送信される利用者情報とその取り扱いについて説明するものです`,
              j031: "",
              j032: "本サイトのご利用に際しましては、本サイト・プライバシーポリシーの内容をあらかじめご確認いただき、ご了承のうえでご利用ください。（本サイト・プライバシーポリシーは、本サイトのご利用開始後も、本サイト内のメニューから随時参照いただけます。）",

              j033: "【送信される情報】",
              j034: "本サイトでは株式会社ユーザーローカルのアクセス解析ツール「User Insight」を利用しており、以下の情報がお客様個人が特定されない統計データとして送信されます。",
              j035: "・本サイトのご利用状況（サイト利用時間、サイト画面のタップ軌跡等の画面操作履歴等）",
              j036: "・ご利用端末に関する情報（機種名、OS・ブラウザバージョン、端末画面サイズ等）",
              j037: "【利用目的】",
              j038: "送信された情報は、本サイトの機能評価・改善およびマーケティング分析のために当社で利用します。",
              j039: "【送信方法・送信先】",
              j040: "株式会社ユーザーローカルへ本情報収集モジュールが自動送信します。",
              j041: "【第三者提供の有無】",
              j042: "User Insightを通じて当社が取得した情報は、お客様個人が特定されない統計データとして以下に記載の第三者に提供される場合があります。",
              j043: "・マーケティング及びサービス利用動向の統計分析・サービス反映のため、株式会社エムティーアイへ提供される場合があります。",
              j044: "【情報収集モジュール提供会社のプライバシーポリシー】",
              j045: "【情報送信の停止手段・影響など】",
              j046: "情報送信の停止をご希望される場合、株式会社ユーザーローカルが提供するオプトアウト機能を用いて情報送信停止の操作をお客様ご自身で行ってください。",
              j047: "※情報送信を停止した場合でも、本サイトのサービス機能は制約なくご利用いただけます。",
              j048: "3. 個人情報の収集および取り扱いについて",
              j049: "本サイトおよび本サイトに付随するサービスにて収集するお客様の情報に個人情報が含まれる場合、実際に収集を行う場面（個人情報の入力・送信を行う画面等）で利用目的、第三者提供の有無、その他法令に定められた事項を都度提示し、お客様の明示的な同意をあらかじめ取得したうえで収集するものとします。",
              j050: "※個人情報とは、個人に関する情報であって、それら情報に含まれる氏名、生年月日、その他の記述によって特定の個人が識別できる情報を指します。（他の情報と容易に照合することができ、それによって特定の個人を識別することが可能となる情報も含みます。）",
              j051: "※当社が個人情報の収集を行う場合の当社全体の保護方針および汎用的な取り扱い基準については、以下の文書をご参照ください。",
              j052: "[個人情報保護方針]:",
              j053: "4.利用者情報の取り扱いに関するお問い合わせ先",
              j054: "[株式会社カラダメディカ 苦情・相談窓口]",
              j055: "5.本サイト・プライバシーポリシーの変更について",
              j056: "本サイトのバージョンアップに伴い、送信される利用者情報の項目、目的、送信先等が変更される場合があり、変更が生じた際には本サイト",
              j057: "・プライバシーポリシーを改定します。変更内容等については、本サイトの新バージョンに付随する最新の本サイト・プライバシーポリシーをご参照ください。",
              j058: "なお、変更内容によっては、必要に応じてお客様の同意確認を改めて取らせていただく場合があります。",
            },
          },
        },
      },
    },
    AppointmentDetail: {
      AppointmentDetailContent: {
        render: {
          div: {
            PrimaryHeading: {
              j001: "予約詳細",
            },
            FormGroup: {
              CancelButton: {
                j001: "ビデオ通話画面へ進む",
                j002: "医療機関詳細ページ",
                j003: "キャンセルする",
              },
            },
            Dialog: {
              j001: "",
              j002: "予約のキャンセルを行いますか？",
              j003: "はい",
              j004: "いいえ",
            },
            CancelConsentDialog: {
              j001: "キャンセルが完了しました",
              j002: "オンライン資格確認の同意可否を選択済みの場合、以下から取消を行ってください。​",
              j003: "同意可否の確認・取消をする",
              j004: "(外部サイトに移動します)",
              j005: "閉じる",
            },
          },
          cancelAppointment: {
            errors: {
              E01: "キャンセル中にエラーが発生しました。しばらくしてから再度お試しください。",
              E04: "対象の予約は医療機関の操作によって承認されませんでした。",
              E05: "対象の予約は診療時間を経過したため、自動的に否認されました。",
              E06: "予約された診療時間を過ぎているため、操作できません。この予約は自動的に否認されます。",
              E07: "診療開始時間を過ぎてからのキャンセルはできません。医療機関へ直接ご連絡ください。",
            },
          },
          paymentError: {
            title: "決済エラーが発生しました。",
            text: "以下のボタンからマイページに遷移可能です。お手数ですがクレジットカード情報を更新してください。",
            CreditCardUpdateButton: {
              j001: "カード情報を更新する",
            },
          },
          ConsentRegistration: {
            title: "オンライン資格確認の同意",
            Create: {
              content:
                "健康保険証利用を申込済のマイナンバーカードをお持ちの方は、スマートフォンでマイナンバーカードを用いたオンライン資格確認の同意可否を選択できます。​\nオンライン資格確認に同意すると、医療機関がご自身の加入している医療保険等の情報をオンラインで確認できます。​\n同じ医療機関で複数の予約をしている場合は、予約日時が一番遅い予約から操作を行ってください。​",
              createButton: {
                title: "同意可否を選択する",
                subText: "(外部サイトに移動します)",
              },
            },
            Update: {
              content:
                "健康保険証利用を申込済のマイナンバーカードをお持ちの方は、スマートフォンでマイナンバーカードを用いたオンライン資格確認の同意可否を選択できます。​\nオンライン資格確認に同意すると、医療機関がご自身の加入している医療保険等の情報をオンラインで確認できます。​\n同じ医療機関で複数の予約をしている場合は、予約日時が一番遅い予約から操作を行ってください。",
              createButton: {
                upperText: "同意可否を未選択の方はこちら",
                title: "同意可否を選択する",
                subText: "(外部サイトに移動します)",
              },
              updateButton: {
                upperText: "同意可否を選択済みの方はこちら",
                title: "同意可否の確認・取消をする​",
                subText: "(外部サイトに移動します)",
              },
            },
            Cancel: {
              content:
                "予約がキャンセルされましたので、オンライン資格確認の同意可否の取消をしてください。\nなお、取消が完了した後もこの文章は表示されます。​",
              cancelButton: {
                title: "同意可否の確認・取消をする​",
                subText: "(外部サイトに移動します)",
              },
            },
            Error: {
              j001: "予期せぬエラーが発生しました。オンライン資格確認の同意可否の選択が行えない場合は、ヘルプ・お問い合わせページよりご連絡ください。",
            },
          },
        },
      },
      MedicineDeliveryEdit: {
        render: {
          Title: {
            j001: "お薬の説明と受取方法の選択",
          },
          Button: {
            j001: "変更する",
            j002: "戻る",
          },
        },
      },
      InterviewSheetAnswerContent: {
        render: {
          Title: {
            j001: "問診内容",
          },
          Button: {
            j001: "編集",
          },
        },
      },
      InterviewSheetAnswerDetail: {
        render: {
          Title: {
            j001: "問診内容",
          },
          Preface: {
            j001: "予約完了後、ビデオ通話開始1時間前までは、予約詳細画面より問診内容を編集することができます。",
          },
        },
      },
      AppointmentFileSharing: {
        render: {
          note: {
            j001: "【画像の注意事項】",
            j002: "＜共有できる画像＞ <br /> ​​​.png・.jpg形式の画像を5枚まで ​",
            j003: "＜共有できる期間＞ <br /> ​予約登録時から予約当日の2週間後まで ",
            j004: "＜共有した画像の表示期間＞ <br /> ​​予約当日の翌月末日まで <br /> ​※表示期間を経過すると自動で削除されます。​",
          },
          button: {
            j001: "画像を追加する",
          },
          noFileContent: {
            j001: "共有画像はありません",
          },
          fileAction: {
            // Add file
            j001: "を追加しました。",
            // Update change file name
            j002: "の変更を保存しました。",
            // Delete file
            j003: "を削除しました。",
          },
        },
        errors: {
          // System error occurs when upload file
          j001: "アップロード中にエラーが発生しました。時間をおいて再度お試しください。",
          //  Upload file error if booking had been rejected
          j002: "予約が否認されたため、画像の追加はできません。",
          // Upload file error if booking had been canceled
          j003: "予約がキャンセルされたため、画像の追加はできません。",
          // If user tries to upload file after 5 files have been selected in another tab or device
          j004: "5枚の画像が共有済みです。不要な画像を削除してから追加してください。",
          // System error occurs when update, delete file and load file
          j005: "エラーが発生しました。時間をおいて再度お試しください。",
          // Check expired upload file error if photo upload deadline had passed
          j006: "共有できる期間を過ぎているため、画像の追加はできません。",
          //  If display period had passed (last date of next month of examination date)
          j007: "この画像は削除されました。",
          // If this file had already been deleted
          j008: "この画像は削除されました。",
        },
      },
    },
    interviewSheet: {
      InterviewSheetAnswerDetail: {
        render: {
          Content: {
            j001: "未回答",
          },
        },
      },
      InterviewSheetAnswerForm: {
        Title: {
          j001: "全{0}問",
          j002: "{0}．{1}",
        },
        Error: {
          j001: "未回答です",
          j002: "100字以内で入力してください",
        },
        Content: {
          j001: "必須",
        },
      },
    },

    Benefits: {
      render: {
        div: {
          HomeHeadingAlignCenter: {
            j001: "ご利用メリット",
          },
        },
      },
    },
    ConfirmationForm: {
      confirm: {
        j001: "認証コードに誤りがあります。",
      },
      resend: {
        j001: "※未指定の項目があります。",
      },
      checkInput: {
        j001: "※未指定の項目があります。",
      },
      render: {
        Fragment: {
          PrimaryHeading: {
            j001: "認証コード入力",
          },
          HomeHeadingLightText: {
            j001: "認証コード送信完了",
          },
          MiddleHeading: {
            j001: "注意事項",
          },
          div: {
            FormAreaCenter: {
              Typography5Center: {
                j001: "{{patientInfo.phoneNumber}}宛に\nSMSで6桁の認証コードを送りました。認証コードを入力してください。",
              },
            },
            div: {
              FormArea: {
                InputTitle: {
                  j001: "認証コード",
                },
                InputText: {
                  j001: "例:123456",
                },
              },
              ButtonArea: {
                FullWidthButton: {
                  j001: "認証する",
                },
                FullWidthButtonSub: {
                  j001: "認証コードを再送",
                },
              },
            },
          },
        },
      },
    },
    InquirySignupSmsFrom: {
      render: {
        Fragment: {
          tip: {
            j001: "認証コードが届かない場合は、以下をご確認ください。",
            j002: "電話での認証方法でも解決できない場合はこちらのフォームからお問い合わせいただくと、本人確認を行ったうえで、当社にて会員登録の手続をいたします。",
            j003: "携帯電話番号は正しく入カされているか",
            j004: "ご利用の端末でSMSの受信拒否設定がされていないか",
            j005: "ご利用の携帯電話番号の契約がSMSを受信できる契約かどうか",
          },
          symbols: {
            j001: "・",
          },
          div: {
            AreaPanel: {
              AreaPanelSummary: {
                AreaName: {
                  j001: "上記で解決できない場合はこちら",
                },
              },
              AreaPrefectures: {
                AreaPrefecturesHeading: {
                  j001: "お問い合わせフォーム",
                },
                Content: {
                  j001: "メールアドレス",
                },
                EmailConfirmLabel: {
                  j001: "確認のためもう一度入力してください",
                },
                FullWidthButton: {
                  j001: "送信​",
                },
              },
            },
            div: {
              FormArea: {
                PanelWellCenter: {
                  Checkbox:
                    "本人確認のため、会員登録画面で入力した個人情報の提供に同意する",
                },
              },
            },
          },
        },
      },
      errorMessage: {
        j001: "予期せぬエラーが発生しました。TOPページに戻り、はじめからやり直してください。",
      },
      checkInput: {
        j001: "▼メールアドレスの形式と一致しません。\n正しいメールアドレスを入力してください。",
        j002: "▼メールアドレスが一致しません。",
      },
    },
    VoiceNotification: {
      render: {
        j001: "60秒たっても認証コードが届かない方へ",
        j002: "{{contact_center_phone_number}}という番号から電話がかかってきます。​",
        j003: "自動音声のガイダンスが流れ、6桁の数字を読み上げますのでお手元にメモをご用意のうえボタンを押してください。",
        j004: "※通話料金は無料です。",
        Buttons: {
          ResendCode: {
            j001: "電話で認証コードを受け取る",
          },
        },
      },
    },
    IvrError: {
      render: {
        commonTitle: "接続がタイムアウトしました​​",
        commonContent:
          "一定時間システムからの応答がありませんでした。しばらくたってからやり直してください。​",
        expiredTitle: "認証コードの有効期限が切れました​​",
        expiredContent:
          "「戻る」から前のページに戻り、「認証コードを再送」を押してください。再発行された認証コードをSMSにてお送りします。",
        limitationTitle: "問題が発生しました",
        limitationContent:
          "「電話で認証コードを受け取る」が10回選択されました。着信がない場合、電話番号を間違えている可能性があります。最初からやり直してください。",
        patientNotFoundTitle: "会員情報が正しく登録されていません​",
        patientNotFoundContent:
          "会員情報が正しく登録されていないため、認証コードをお知らせできません。メニューの「ヘルプ・お問い合わせ」を押し、ページの下部にある「○○をご利用の方」より、お問い合わせください。",
        backBtn: "戻る",
      },
    },
    ConsumerPolicy: {
      render: {
        j001: "会員ID：",
        j002: `${service_name}`,
        j003: "メールの件名、及び、上記の情報は変更しないでください。",
        j004: "お問い合わせ内容を以下にご記入ください",
        Container: {
          div: {
            PrimaryHeading: {
              j001: "特定商取引法",
            },
            SecondaryHeading: {
              j001: "事業者名",
              j002: "販売責任者",
              j003: "所在地",
              j004: "お問い合わせ",
              j005: "料金",
              j006: "サービスの価格・利用額以外に発生する料金等",
              j007: "商品の引渡時期",
              j008: "返品/キャンセル",
              j009: "動作環境",
            },
            Paragraph: {
              j001: "株式会社カラダメディカ",
              j002: "菅原誠太郎",
              Text: {
                j001: "〒160-0023",
                j002: "東京都新宿区西新宿3-20-2",
              },
              j003: "株式会社カラダメディカ",
              j004: "受付時間:平日9:00～17:30",
              j005: "(土日祝年末年始除く)",
              j006: "医療機関によって異なる",
              j007: "通信料及び消費税などが別途かかります。",
              j008: "決済手続き完了後即時。一部商品に関しては6～10営業日要する場合がございます。",
              j009: "商品の特性上、不可",
              PrimaryLinkToNoUnderline: {
                j001: "よくあるご質問/お問い合わせ",
              },
              j010: "ページ内の「動作環境について」をご確認ください。",
            },
          },
        },
      },
    },
    DuringChattingMessage: {
      render: {
        MessagePanel: {
          span: {
            j001: "ビデオ通話中の診療があります。",
          },
          LinkChat: {
            BackToChat: {
              j001: "通話画面に戻る＞",
            },
          },
        },
      },
    },
    EmailForm: {
      checkInput: {
        j001: "▼メールアドレスの形式と一致しません。\n正しいメールアドレスを入力してください。",
        j002: "▼メールアドレスが一致しません。",
      },
      submit: {
        j001: "▼内部エラーが発生しました",
      },
      render: {
        div: {
          PrimaryHeading: {
            j001: "メールアドレス",
          },
          Container: {
            WarningContent: {
              j001: "docomo、au、SoftBankなどキャリアメールアドレスのご登録はお控えください。\nGmailなどPCでも受信可能なメールアドレスでご登録をお願いいたします。",
            },
            Content: {
              j001: "診療予約完了時や診療時間前にご登録のメールアドレスまたは電話番号にお知らせをお送りいたします。",
            },
            EmailConfirmLabel: {
              j001: "確認のためもう一度入力してください。",
            },
            Note: {
              j001: "登録されたメールアドレスに本人確認用のメールを送信いたします。メールに記載されている認証コードを次の画面でご入力ください。",
            },
            FullWidthButton: {
              j001: "メールアドレスを設定する",
            },
          },
        },
      },
    },
    Footer: {
      RenderBottomDate: {
        Fragment: {
          BottomDate: {
            BottomDescription: {
              j001: "選択されている予約日時",
            },
            BottomDay: {
              j001: "年",
              j002: "月",
              j003: "日",
            },
          },
        },
      },
      render: {
        Fragment: {
          Root: {
            TextParagrap: {
              Blook: {
                Anchor: {
                  j001: "株式会社カラダメディカ",
                },
              },
            },
          },
        },
      },
    },
    Header: {
      render: {
        Consumer: {
          Root: {
            Container: {
              Logo: {
                Img: {
                  j001: `${service_name}`,
                },
              },
              DecorativeIcon: {
                LoginButton: {
                  j001: "ログイン",
                },
              },
            },
          },
        },
        Standby: {
          j001: "の枠",
          j002: "予約時刻までにビデオ通話画面に進んでください",
          j003: "ビデオ通話画面へ進む",
        },
      },
    },
    Home: {
      render: {
        div: {
          ButtonSignUp: {
            j001: "会員登録",
          },
          ButtonPadding: {
            j001: "会員登録",
            j002: "会員登録",
          },
        },
        // Notification: {
        //   Title: {
        //     j001: "＜【復旧】ビデオ通話が開始されない不具合について＞",
        //   },
        //   Body: {
        //     j001: "発生しておりました、ビデオ通話待機画面で待機していても通話が開始されない事象が解消いたしました。\nご迷惑おかけいたしましたことを深くお詫び申し上げます。",
        //   },
        //   SubBody: {
        //     j001: "詳細は左上のメニューから",
        //     PrimaryLinkTo: {
        //       j001: "利用規約",
        //     },
        //     j002: "をご確認ください。",
        //   },
        // },
      },
    },
    HomePageCheck: {
      err: {
        title: "ご利用前にご確認ください",
        address: "住所情報の登録が必要です",
        insuranceCard: "保険証画像のアップロードが必要です",
      },
      button: {
        j001: "住所を登録する",
        j002: "クレジットカードを登録する",
      },
    },
    HospitalDetail: {
      render: {
        div: {
          PrimaryHeading: {
            j001: "医療機関詳細",
          },
        },
      },
    },
    InquiryList: {
      generateMessage: {
        j001: "会員ID：",
        j002: `${service_name}`,
        j003: "メールの件名、及び、上記の情報は変更しないでください。",
        j004: "お問い合わせ内容を以下にご記入ください。",
        j005: "情報が不足している場合、十分なご案内ができない場合があります。",
        j006: "【発生日時】",
        j007: "【ご予約の医療機関名】​​",
        j008: "【ご予約日時】",
        j009: "【どのような操作をされたのか】​​",
        j010: "【どんな問題が起きているか（スクリーンショットを取得できるようでしたらご添付ください。）】",
      },
      render: {
        Fragment: {
          div: {
            PrimaryHeading: {
              j001: "お問い合わせ",
            },
            InquiryIntro: {
              j001: "FAQを見ても解決できなかった場合は、以下の注意事項をご確認のうえ、こちらからお問い合わせください。",
            },
            InquiryNotify: {
              j001: "予約当日の急ぎのお問い合わせについて",
              j002: "予約時間になってもビデオ通話が開始しない等の緊急のお問い合わせは、ご予約の医療機関へ直接ご連絡ください。",
            },
            InquiryInformation: {
              ReceptionTime: {
                j001: "受付時間",
                j002: "24時間受付",
              },
              ResponseTime: {
                j001: "ご回答時間",
                j002: "9:00～17:30",
                j003: "（土日祝日・12月28日～1月3日を除く）",
                j004: "全てのご利用者様からのお問合せを順次回答しております。ご回答までに数日かかる場合がございますので、予めご了承ください。",
              },
              AcquisitionInformation: {
                j001: "取得情報",
                j002: "OSバージョン・機種名",
              },
              NotesOnInquiries: {
                title: "お問い合わせ時の注意",
                content: {
                  j001: "・メール内では、絵文字はご利用になれません。",
                  j002: "・お問い合わせをいただきましたら、受付完了の旨、返信をさせていただきます。もし、返信がない場合は、お手数おかけいたしますが、再度お問い合わせをお願いいたします。",
                  j003: "・迷惑メール対策をしている方は、返信メールが届きませんので、「@caradamedica.co.jp」からのメールを受信できるよう、予めドメイン指定受信の設定をお願いいたします。",
                  j004: "・不具合などご利用中にお困りのことが発生している場合は、以下の情報を詳細に記載をお願いいたします。",
                  j005: "　・発生日時",
                  j006: "　・ご予約の医療機関名とご予約日時",
                  j007: "　・どのような操作をされたのか",
                  j008: "　・どんな問題が起きているか（スクリーンショットを取得できるようでしたら添付ください。）",
                },
              },
            },
            InquiryButton: {
              j001: "メールでお問い合わせ",
              j002: "（メールアプリが起動します）",
              j003: "戻る",
            },
          },
        },
      },
    },
    LeftMenu: {
      render: {
        Drawer: {
          Container: {
            MenuHeader: {
              j001: "メニュー",
            },
            MenuList: {
              LinkContainer: {
                Card: {
                  Typography3: {
                    j001: "マイページ",
                    j002: "利用規約",
                  },
                },
              },
            },
          },
        },
      },
    },
    ListAppointment: {
      render: {
        div: {
          Fragment: {
            HomeHeadingAlignCenter: {
              j001: "あなたの予約",
            },
          },
        },
      },
    },
    ListAppointmentMyPage: {
      render: {
        div: {
          Fragment: {
            SecondaryHeading: {
              j001: "診療履歴",
            },
          },
        },
      },
    },
    ListHospital: {
      render: {
        div: {
          HomeHeadingAlignLeft: {
            j001: "病院/クリニック",
            j002: "薬局",
            j003: " ",
          },
        },
      },
    },
    MedicalCareMenu: {
      render: {
        div: {
          HomeHeadingAlignLeft: {
            j001: "メニュー",
            j002: "診療メニュー",
          },
          Container: {
            ContainerButton: {
              FullWidthButton: {
                j001: "予約する",
              },
            },
            OnlineOrOffline: {
              TextMedicalMethod: {
                j001: "オンライン",
                j002: "来院",
              },
            },
          },
        },
        MenuAccordion: {
          j001: "その他のメニュー",
          j002: "（診療費は個人負担になります）",
        },
      },
      ApprovalWarningDialog: {
        DialogTitle: {
          Title: {
            j001: "オンライン診療予約\nの注意事項",
          },
        },
        DialogContent: {
          j001: "予約は、医療機関の承認後に確定します。",
          j002: "万が一、医師が対面での診療を必要と判断した場合など、承認されないことがあります。",
          j003: "予約にはクレジットカード、住所の登録が必要です。",
        },
        DialogActions: {
          OkButton: {
            j001: "予約へ進む",
          },
          CloseButton: {
            j001: "戻る",
          },
        },
      },
    },
    MyPage: {
      render: {
        div: {
          PrimaryHeading: {
            j001: "マイページ",
          },
          SecondaryHeading: {
            j001: "登録情報の確認・変更",
          },
          Enterprise: {
            j001: "所属法人名",
            j002: "利用プログラム",
            j003: "（利用可能なプログラムはありません）",
          },
          ListCard: {
            LinkContainer: {
              Card: {
                j001: "会員情報",
                j002: "パスワード",
                j003: "クレジットカード情報",
                j004: "住所情報",
                j005: "メールアドレス",
                j006: "保険証情報",
              },
            },
          },
          PhoneNumber: {
            j001: "ログイン中の電話番号:",
          },
        },
      },
    },
    mypage: {
      InsuranceCardDetail: {
        title: "保険証情報",
        text: "保険証の画像は毎月登録が必要になります。\nご登録いただいた画像は、毎月1日0:00に自動的に削除されます。保険証が必要な診療の予約が翌月以降にある際は、診察前に保険証をアップロードしてください。",
        updateDate: "最終更新日",
        nofile: "現在、保険証が登録されていません。",
        button: "保険証を登録する",
        lnlntext:
          "ここからは、WEBブラウザで表示されます。\nサイトにログイン後、保険証を登録してください。",
      },
      InsuranceCardConfirm: {
        title: "保険証情報",
        confirm: "こちらの画像を登録しますか？",
        reSelect: "画像を変更する",
        button: "確定する",
      },
      InsuranceCardComplete: {
        title: "保険証情報",
        text: "登録が完了しました。",
        button: "マイページに戻る",
      },
      InsuranceCardCaution: {
        title: "画像登録時のご注意",
        text: "保険証全体が入るように撮影してください。\nアップロードする画像は、記載内容がはっきりと読み取れる画像をお選びください。 ",
        ok: {
          title: "良い例",
          text: "・保険証全体が写っている\n・ピントが合っている\n・光が反射していない\n・保険証番号が確認できる",
        },
        ng: {
          title: "悪い例",
          text: "文字や画像が不鮮明な場合、お手続きができません。\n以下の点には特にご注意ください。",
          example1: "ぼやけている",
          example2: "写真が切れている",
          example3: "光が反射している",
          example4: "影が入っている",
          example5: "対象が小さい",
          example6: "加工されている",
        },
      },
      InsuranceCardErr: {
        title: "保険証情報",
        button: "画像を変更する",
        Errors: [
          {
            code: "invalidExtension",
            text: "アップロードできるファイル形式は、.png/.jpgです。選択したファイルを確認してください。",
          },
          {
            code: "tooBigFileSize",
            text: "ファイルサイズが大きすぎます。10MB未満のファイルをご用意ください。",
          },
          {
            code: "localFileModified",
            text: "選択されたファイルが存在しません。存在するファイルを選択してください。",
          },
          {
            code: "uploadErr",
            text: "アップロード中にエラーが発生しました。時間を空けて再度お試しください。",
          },
        ],
      },
    },
    NotificationList: {
      render: {
        Container: {
          Card: {
            j001: "【ご利用料金が確定しました】",
            j002: "【決済エラーが発生しました】",
            j003: "【医療機関からファイルが届きました】",
            j004: "【予約が設定されました】",
            j005: "#hospitalName[#menuDisplayName]",
            j006: "問診票をご記入ください",
            j007: "こちらの予約はキャンセルされました",
            j008: "内容をご確認ください",
            j009: "ファイルが削除されました",
            j010: "【ご利用ありがとうございました】",
            j011: "オンライン資格確認の同意可否を取消してください",
            Typography: {
              Schedule: {
                FreeButton: {
                  j001: "未読",
                },
              },
            },
          },
        },
      },
    },
    PrivacyPolicy: {
      mail: {
        j001: "会員ID：",
        j002: `${service_name}`,
        j003: "メールの件名、及び、上記の情報は変更しないでください。",
        j004: "お問い合わせ内容を以下にご記入ください",
      },
    },
    QRCode: {
      export: {
        QRCodeTag: {
          div: {
            j001: "スマートフォンの",
            j002: "ご利用はこちら",
          },
        },
      },
    },

    UsingFlow: {
      render: {
        div: {
          HomeHeadingAlignCenter: {
            j001: "ご利用の流れ",
          },
        },
      },
    },
    VerifyEmailForm: {
      render: {
        div: {
          PrimaryHeading: {
            j001: "メールアドレス",
          },
          Container: {
            Title: {
              j001: "認証コード入力",
            },
            Content: {
              j001: "メールに送信された認証コードを",
              j002: "入力してください。",
            },
            Error: {
              j001: "認証コードに誤りがあります。",
              j002: "もう一度入力してください。",
            },
            ResendCode: {
              j001: "メールアドレス入力画面に戻る",
              j002: "認証コードを再送する",
            },
          },
          Dialog: {
            OnlineChatDialogHeader: {
              j001: "メールを送信しました。",
            },
            OnlineChatDialogBody: {
              InfoMessage: {
                Typography2: {
                  j001: "メールに送信された認証コードをご確認ください。",
                },
              },
            },
          },
        },
      },
    },
    Announcement: {
      title: "重要なお知らせ",
      text: "下記の期間にシステムメンテナンスを実施いたします。期間中は全ての機能をご利用いただくことができません。ご迷惑をおかけしますが、ご理解とご協力のほど宜しくお願いいたします。",
      dateTitle: "【メンテナンス日時】",
      note: "※メンテナンス終了時刻は前後する場合がございます。",
    },
    SearchHospital: {
      render: {
        div: {
          HomeHeadingAlignLeft: {
            j001: "医療機関を探す",
          },
          SearchTabs: {
            j001: "病院/クリニック",
            j002: "薬局",
          },
          SplitLine: {
            j001: "|",
          },
          SearchContents: {
            j001: "都道府県から探す",
            j002: "予約履歴から探す",
          },
        },
      },
    },
    SearchHospitalByAddress: {
      render: {
        Title: {
          j001: "都道府県から探す（病院/クリニック）",
          j002: "都道府県から探す（薬局）",
        },
      },
    },
    SearchResult: {
      render: {
        div: {
          Title: {
            j001: "検索結果",
          },
          Content: {
            j001: "件",
          },
        },
      },
    },
    enterprise: {
      EnterpriseTopContent: {
        render: {
          Buttons: {
            j001: "利用プログラムを追加する​",
          },
        },
      },
      EnterpriseAuthenticationContent: {
        render: {
          j001: "新規でご利用の方",
          j002: "すでに会員登録をされている方​",
          Buttons: {
            j001: "会員登録",
            j002: "ログイン",
          },
        },
      },
      EnterpriseContractPlanList: {
        render: {
          j001: "登録内容",
          j002: "所属法人名",
          j003: "利用プログラム",
          j004: "（利用可能なプログラムはありません）",
          j005: "従業員番号",
          j006: "まだ登録していません\n​マイページから追加できます",
        },
      },
      EnterpriseConfirmForm: {
        render: {
          Button: {
            j001: "登録する",
            j002: "戻る",
          },
          Submit: {
            j001: "無効な招待コードです。入力内容をご確認ください。",
            j002: "複数回誤った入力がされたため、ロックされました。しばらく時間を空けて再度お試しください。",
            j003: "入力された招待コードは有効な状態ではありません。所属法人のご担当者にお問い合わせください。",
            j004: "入力された招待コードは有効期限が過ぎています。所属法人のご担当者にお問い合わせください。",
            j005: "入力された招待コードに紐づく福利厚生プランが存在しません。所属法人のご担当者にお問い合わせください。",
            j006: "入力された招待コードは、このアカウントに紐づいている法人のものではありません。所属法人のご担当者にお問い合わせください。",
            j007: "入力された招待コードで現在利用可能なプログラムがございません。お渡しした利用案内にて所属法人のプログラム利用期間をご確認ください。",
            j008: "入力された1つ以上の招待コードに紐づく法人が一致しません。所属法人のご担当者にお問い合わせください。",
            j009: "予期せぬエラーが発生しました。初めからやり直してください。",
          },
        },
      },
      EnterpriseStepperForm: {
        RegisterSteps: {
          j001: "会員登録",
          j002: "ログイン",
          j003: "招待コード入力",
          j004: "登録内容確認",
          j005: "完了",
        },
        SignInFlow: {
          j001: "ログイン",
          j002: "招待コード入力",
          j003: "登録内容確認",
          j004: "完了",
        },
      },
    },
    account: {
      Cancellation: {
        render: {
          Heading: {
            j001: "退会",
          },
          Container: {
            Title: {
              j001: "{{patientInfo.phoneNumber}}​のアカウントの退会を行います。",
              j002: "次の注意事項にご同意のうえ、退会にお進みください。",
            },
            Content: {
              j001: "退会の注意事項",
              j002: "退会すると個人情報はすべて削除されます。一度退会したアカウント情報や診療履歴を確認・復活することができません。",
              j003: "これまでの診療履歴をすべて確認できなくなります。",
              j004: "再度登録した場合でも、退会前の登録内容や診療履歴の情報を引き継ぐことはできません。​",
              j005: "​以下の診療が残っている場合、退会することができません。​",
            },
            SubContent: {
              j001: "‐診療前の予約​",
              j002: "‐診療後、決済が完了していない予約",
              j003: "（決済完了から3か月後に退会することができます）",
            },
            CheckboxLabel: {
              j001: "上記内容に同意する​",
            },
            FullWidthButton: {
              j001: "退会する",
            },
          },
        },
      },
      CancellationError: {
        systemError: {
          commonTitle: "接続がタイムアウトしました",
          commonContent:
            "一定時間システムからの応答がありませんでした。しばらくたってからやり直してください。",
          backBtn: "戻る",
        },
        Header: {
          j001: "退会",
        },
        irregularError: {
          Title: {
            j001: "診療前の予約があるため、退会できません。",
            j002: "登録いただいたクレジットカード会社の対応が完了するまで、退会できません。",
            j003: "金額を確定していない診療があるため、退会できません。",
            j004: "受診していない診療の予約があるため、退会できません。",
          },
          Content: {
            j001: "受診いただく、もしくは予約をキャンセルしてください。",
            j002: "予約をキャンセルする場合は、TOPページの「あなたの予約」からキャンセルしてください。​",
            j003: "診療当日以降のキャンセルの場合はキャンセル料が発生する場合があります。",
            j004: "以下の内容をご確認のうえ、退会可能日以降に退会手続きをしてください。​",
            j005: "予約した医療機関へ直接ご連絡のうえ、金額を確定するように依頼してください。​\nそのうえで、ご登録いただいたクレジットカード会社の対応が完了後、退会が可能となります。退会可能となるまでお時間をいただきますので、ご了承ください。​",
            j006: "以下の内容をご確認のうえ、こちらからお問い合わせください。",
          },
          sendMailBtn: "メールでお問い合わせ",
          helperText: "（メールアプリが起動します）",
        },
        contact: {
          j001: "会員ID：",
          j002: `${service_name}`,
          j003: "メールの件名、及び、上記の情報は変更しないでください。",
          j004: "お問い合わせ内容を以下にご記入ください",
        },
      },
    },
    ReloadPopUp: {
      title: "操作を続けるにはページを\n再読み込みしてください",
      description:
        "しばらく操作がありませんでした。\n正常に動作しない可能性がありますので、このページを再読み込みしてください。",
      reloadButton: "再読み込みする",
    },
  },
  pages: {
    auth: {
      ChangePasswordPage: {
        change: {
          j001: "未入力の項目があります。",
          j002: "新しいパスワードが一致しません。",
          j003: "パスワードを変更しました。",
          j004: "今のパスワードを確認して下さい",
          j005: "パスワードを一定回数間違えた為、しばらくしてから再度ログインしてください",
        },
        render: {
          SectionSub: {
            j001: "パスワード",
            j002: "パスワードを入力してください",
            InputText: {
              j001: "現在のパスワード",
              j002: "新しいパスワード",
              j003: "確認のためもう一度入力してください。",
              placeholder: "大文字と小文字を含めて８文字以上",
            },
            Button: {
              j001: "パスワードを変更する",
            },
          },
        },
      },
      ForgotPasswordPage: {
        errorMessages: {
          j001: "入力内容をご確認下さい",
          j002: "認証コードをご確認下さい",
          j003: "パスワードを空にすることはできません。",
          j004: "パスワードが一致しません。",
          j005: "パスワードポリシーを満たしていません。8文字以上の半角英数字を使用してください。",
          j006: "会員情報が正しく登録されていないため、パスワードを再設定できません。ヘルプ・お問い合わせページよりご連絡ください。",
          j007: "一定回数を超えたためロックされています。少し時間をあけて再度お試しください。",
          j999: "予期せぬエラーが発生しました。ヘルプ・お問い合わせページよりご連絡ください。",
        },
        getErrorMessage: {
          j001: "未入力の項目があります。",
        },
        send: {
          j001: "電話番号は空にできません。",
        },
        submit: {
          j001: "パスワードを入力してください",
          j002: "電話番号とパスワードを別にしてください",
          j003: "パスワードが一致しません。",
          j004: "変更しました",
        },
        render: {
          Fragment: {
            PrimaryHeading: {
              j001: "パスワード再設定",
              j002: "パスワード再設定",
            },
            Container: {
              Typography: {
                j001: "電話番号を入力してください。",
                j002: "認証コードをSMSにてお送りします。",
                j003: "SMSで認証コードを送りました。",
                j004: "認証コードを入力して下さい。",
              },
              PhoneInput: {
                j001: "電話番号を入力してください",
                j002: "例:123456",
                j003: "電話番号",
                j004: "認証コード",
              },
              FullWidthButton: {
                j001: "送信",
                j002: "ログインページへ戻る",
                j003: "パスワードを再設定する",
              },
              ButtonOutlined: {
                j001: "認証コードを再送",
              },
              InputTextPassword: {
                j001: "大文字と小文字を含めて８文字以上",
                j002: "大文字と小文字を含めて８文字以上",
                j003: "新しいパスワード",
                j004: "確認のためもう一度入力してください。",
              },
              ResetPassword: {
                j001: "{{patientInfo.phoneNumber}}​ 宛に \nSMSで6桁の認証コードを送りました。認証コードを入力してください。",
                j002: "新しいパスワードを半角英数字で入力し、「パスワードを再設定する」ボタンを押してください。",
              },
            },
            SecondaryHeading: {
              j001: "パスワード再設定",
            },
            MiddleHeading: {
              j001: "注意事項",
            },
          },
        },
      },
      NewPasswordPage: {
        change: {
          j001: "パスワード不一致",
        },
        render: {
          Fragment: {
            Section: {
              j001: "新パスワード設定",
              FormGroup: {
                FormGroupHeading: {
                  j001: "新しいパスワード",
                  j002: "確認のためもうー度入力してください。",
                },
                InputTextPassword: {
                  j001: "大文字と小文字含めて8文字以上",
                  j002: "大文字と小文字含めて8文字以上",
                },
              },
              ButtonSubmit: {
                j001: "設定する",
              },
            },
          },
        },
      },
      SignInPage: {
        showVerifyCaptchaError: {
          j001: "認証できませんでした。しばらく経ってから再度お試しください。",
        },
        checkInput: {
          j001: "電話番号またはパスワードが正しくありません。",
        },
        signIn: {
          j001: "電話番号またはパスワードが正しくありません。5回間違えると一定時間アカウントがロックされます。",
          j002: "アカウントがロックされています。少し時間を空けてから、再度お試しください。",
          j003: "会員情報が正しく登録されていないため、ログインできません。ヘルプ・お問い合わせページよりご連絡ください。",
        },
        render: {
          Section: {
            j001: "ログイン",
            InputFormHeaderNoIcon: {
              j001: "会員登録が完了しました",
            },
            Description: {
              j001: "ご登録いただいた電話番号とパスワードでログインし、招待コードの入力へお進みください。",
              j002: "ご登録いただいた電話番号とパスワードでログインしてください。",
            },
            InputText: {
              j001: "電話番号を入力してください",
            },
            InputTextPassword: {
              j001: "パスワードを入力してください",
            },
            label: {
              j001: "電話番号",
              j002: "パスワード",
            },
            Note: {
              j001: "パスワードをお忘れの方は",
              PrimaryLink: {
                j001: "こちら",
              },
            },
            Button: {
              j001: "ログイン",
            },
          },
          SignUpSection: {
            Button: {
              j001: "会員登録はこちら",
            },
          },
        },
      },
      SignUpPage: {
        j001: "男性",
        validateInput: {
          j001: "※未入力の項目があります。",
          j002: "※入力内容をご確認ください。",
          j003: "パスワードを入力してください",
          j004: "パスワードを再度入力してください",
          j005: "電話番号とパスワードを別にしてください",
          j006: "生年月日または携帯電話番号が含まれています。再度設定してください。",
          j007: "※パスワードが一致しません。",
          j008: "※利用規約及び個人情報保護方針に同意してください。",
          j009: "使用できない文字が入力されています。「お名前（姓）」を修正してください。",
          j010: "使用できない文字が入力されています。「お名前（名）」を修正してください。",
          j011: "使用できない文字が入力されています。「フリガナ（セイ）」を修正してください。",
          j012: "使用できない文字が入力されています。「フリガナ（メイ）」を修正してください。",
          j013: "SMSを受信できる電話番号を登録してください。",
          j014: "「お名前（姓）」は、全角で入力してください。",
          j015: "「お名前（名）」は、全角で入力してください。",
          j016: "「フリガナ（セイ）」は、全角で入力してください。",
          j017: "「フリガナ（メイ）」は、全角で入力してください。",
          j018: "「フリガナ（セイ）」は、カタカナで入力してください。",
          j019: "「フリガナ（メイ）」は、カタカナで入力してください。",
          j020: "使用できない文字が入力されています。「市区町村」を修正してください。",
          j021: "使用できない文字が入力されています。「番地」を修正してください。",
          j022: "使用できない文字が入力されています。「建物名・部屋番号」を修正してください。",
          j023: "不正な郵便番号が入力されています。",
        },
        render: {
          Fragment: {
            PrimaryLinkTo: {
              j001: "利用規約",
              j002: "個人情報の取り扱い",
              j003: "サイトプライバシーポリシー",
            },
            j001: "及び",
            j002: "についてに同意する",
          },
          div: {
            PrimaryHeading: {
              j001: "会員情報登録",
            },
            PrimaryHeadingInvertAlignCenter: {
              j001: "無料でご登録いただけます。",
            },
            InputFormHeader: {
              j001: "お名前（全角）",
              j002: "フリガナ（全角カタカナ）",
              j003: "性別",
              j004: "生年月日",
              j005: "携帯電話番号（半角数字）",
              j006: "パスワード（半角英数字）",
            },
            FormAreaMargin: {
              NamedInputTextMargin: {
                j001: "10文字以内",
                j002: "姓",
                j003: "名",
                j004: "セイ",
                j005: "メイ",
              },
            },
            FormArea: {
              RadioGroup: {
                Radio: {
                  j001: "男性",
                  j002: "女性",
                },
              },
              FormGroup: {
                InputTextPassword: {
                  j001: "大文字小文字含め8文字以上",
                },
                FormGroupHeading: {
                  j001: "確認のためもう1度入力してください。",
                },
              },
              InputText: {
                j001: "例：09000000000",
              },
            },
            WellCenter: {
              SubmitButton: {
                j001: "次へ",
              },
            },
          },
        },
      },
    },
    lnln: {
      LnlnInfo: {
        j001: "婦人科受診をもっと便利に　ルナルナへ記録したデータを受診時に活用できるルナルナ メディコ",
        j002: "婦人科受診の際に、ルナルナに記録しているデータ(基礎体温、生理日など)を医師に見せることができる、ルナルナの機能です。\n事前に発行した6桁のデータ番号を伝えるだけで、あなたの基礎体温や生理日などの記録データを、医師が使用しているPCやタブレット上に表示することができます。",
        j012: "事前に発行した6桁のデータ番号を伝えるだけで、あなたの基礎体温や生理日などの記録データを、医師が使用しているPCやタブレット上に表示することができます。",
        j003: "※オンライン相談の場合は、事前にデータ番号を発行し、ビデオ通話で医師にお伝えください。",
        j004: "紙に書き写す手間無しで、",
        j005: "医師と一緒に画面を見ながら受診できます！",
        j006: "アプリ画面イメージ：記録データをそのままみてもらえます",
        j007: "※当機能は無料でご利用いただけます。",
        j008: "ご利用方法",
        j009: "ルナルナの施設詳細ページにある「ルナルナデータ番号発行」ボタンを選択してください。",
        j010: "※当機能は「ルナルナ メディコ」を導入している婦人科でのみご利用いただけます。通院先が未導入の場合は、データ番号の発行ができませんのでご注意ください。",
        j011: "ルナルナの施設情報に戻る",
      },
    },
    payment: {
      CardInfoPage: {
        render: {
          notRegistered: {
            Fragment: {
              Row: {
                Typography2: {
                  j001: "クレジットカード情報が登録されていません",
                },
                FullWidthButton: {
                  j001: "登録する",
                },
              },
            },
          },
          main: {
            Fragment: {
              PrimaryHeading: {
                j001: "クレジットカード情報",
              },
            },
          },
        },
      },
      RegisterPage: {
        render: {
          Fragment: {
            PrimaryHeading: {
              j001: "クレジットカード情報変更",
            },
          },
        },
        validateLimitOneCard: {
          j001: "複数のカード情報を登録できません。登録済みのカード情報は、マイページから変更できます。",
        },
      },
      UpdatePage: {
        render: {
          Fragment: {
            PrimaryHeading: {
              j001: "クレジットカード情報変更",
            },
            Container: {
              Fragment: {
                Row: {
                  Typography2: {
                    j001: "カード番号の登録を行いました",
                  },
                  FullWidthButton: {
                    j001: "マイページ",
                  },
                },
              },
            },
          },
        },
      },
    },
    AddressDetailPage: {
      getNameByPrefectureCode: {
        j001: "都道府県を正しく入力してください",
      },
    },
    AddressPage: {
      getAddressFromPostalCode: {
        j001: "不正な郵便番号が入力されています。",
      },
      validatePostalCodeAndAddress: {
        j001: "郵便番号と住所が一致していません。",
      },
      validate: {
        j001: "※入力内容をご確認ください",
        j002: "入力内容をご確認ください※住所に番地がついていない場合、「番地」欄には、『無番地』と入力してください。",
        j003: "使用できない文字が入力されています。「市区町村」を修正してください。",
        j004: "使用できない文字が入力されています。「番地」を修正してください。",
        j005: "使用できない文字が入力されています。「建物名・部屋番号」を修正してください。",
      },
      update: {
        j001: "チェックを入れてください。",
      },
    },
    EmailCompletePage: {
      render: {
        div: {
          PrimaryHeading: {
            j001: "メールアドレス",
          },
          Container: {
            Content: {
              j001: "メールアドレスの登録が完了しました。",
            },
          },
        },
      },
    },
    ReservationInterviewSheetPage: {
      render: {
        Title: {
          j001: "問診票",
        },
      },
    },
    ReservationUploadFilePage: {
      render: {
        Title: {
          j001: "画像共有​",
        },
      },
    },
    ReservationMedicineDeliveryPage: {
      render: {
        Errors: {
          j001: "診療時間を超えたため、変更できません。",
        },
      },
    },
    ReservationUploadFileErrorPage: {
      render: {
        Titles: {
          j001: "画像共有​",
          j002: "エラー",
        },
        Errors: {
          j001: "共有できない画像形式です。",
          j002: ".png・.jpg形式の画像を選択してください。",
          j003: "画像サイズが大きすぎます。​",

          j004: "10MB未満の画像を選択してください。​",
        },
        Buttons: {
          j001: "画像を選択する",
          j002: "戻る",
        },
      },
    },
    InterviewSheetAnswerUpdatePage: {
      render: {
        Title: {
          j001: "問診内容を編集",
        },
        Preservation: {
          Error: {
            j001: "問診票の更新期限を過ぎたため保存できません。",
            j002: "予約内容に更新があったため、保存できません。予約内容をご確認ください。",
            j003: "予期せぬエラーが発生しました。TOPページに戻り、はじめからやり直してください。",
          },
        },
        Button: {
          j001: "保存",
        },
      },
    },
    AppointmentDetailPage: {
      render: {
        Title: {
          j001: "予約内容",
          j002: "問診票",
          j003: "共有",
        },
        Head: {
          j001: "予約詳細",
          j002: "問診内容を編集",
        },
      },
    },
    MyPageNew: {
      componentDidMount: {
        j001: "更新しました",
      },
    },
    NotFound: {
      render: {
        Fragment: {
          Container: {
            Title: {
              j001: "お探しのページは",
              j002: "見つかりませんでした",
            },
            j001: "申し訳ございませんが、ご指定のページは一時的にアクセスできない状況にあるか、移動もしくは削除された可能性がございます。お手数おかけいたしますが、再度URLをお確かめください。",
          },
        },
      },
    },
    NotSupport: {
      render: {
        Fragment: {
          Container: {
            j001: "『CARADA オンライン診療』はお使いの端末に現在対応しておりません。",
            j002: "大変ご迷惑をおかけいたしますが、ご理解頂けるよう御願い致します。",
          },
        },
      },
    },
    NotAccess: {
      render: {
        Fragment: {
          Container: {
            Title: {
              j001: "アクセスできません",
            },
            j001: "申し訳ございませんが、ご利用ブラウザのキャッシュにより、ページを表示することができません。 ブラウザのキャッシュを削除のうえ、再度アクセスください。",
            j002: "＜キャッシュの削除方法＞",
            j003: "・Safariをご利用の方",
            j004: "ご利用端末の「設定アプリ」から「Safari」を選択して削除してください。",
            j005: "・Google Chrome/Google Mobile Chromeをご利用の方",
            j006: "ブラウザの「設定」から削除してください。",
          },
        },
      },
    },
    OnlineChatFinishPage: {
      contact: {
        j001: "会員ID：",
        j002: `${service_name}`,
        j003: "メールの件名、及び、上記の情報は変更しないでください。",
        j004: "お問い合わせ内容を以下にご記入ください",
      },
    },
    OnlineChatPage: {
      leaveRoom: {
        j001: "退出しました",
      },
    },
    NotificationListPage: {
      getNotificationList: {
        j001: "お知らせはありません",
      },
      render: {
        loadMore: {
          Button: {
            j001: "もっと見る",
          },
        },
        main: {
          Fragment: {
            PrimaryHeading: {
              j001: "お知らせ",
            },
          },
        },
      },
    },
    ReservationCompletePage: {
      render: {
        Title: {
          j001: "予約完了",
          j002: "予約が完了しました",
          j003: "予約を受け付けました",
          j004: "※予約は医療機関の承認後に確定します",
        },
        ConsentRegistration: {
          j001: "オンライン資格確認の同意",
          j002: "健康保険証利用を申込済のマイナンバーカードをお持ちの方は、スマートフォンでマイナンバーカードを用いたオンライン資格確認の同意可否を選択できます。",
          j003: "詳細を確認する",
          RegisterDialog: {
            j001: "オンライン資格確認の同意",
            j002: "健康保険証利用を申込済のマイナンバーカードをお持ちの方は、スマートフォンでマイナンバーカードを用いたオンライン資格確認の同意可否を選択できます。​\nオンライン資格確認に同意すると、医療機関がご自身の加入している医療保険等の情報をオンラインで確認できます。",
            j003: "同意可否を選択する",
            j004: "(外部サイトに移動します)",
            j005: "戻る",
          },
          Error: {
            j001: "予期せぬエラーが発生しました。オンライン資格確認の同意可否の選択が行えない場合は、ヘルプ・お問い合わせページよりご連絡ください。",
          },
        },
        Information: {
          j001: "予約日時",
          j002: "医療機関名",
          j003: "メニュー",
          j004: "通知",
          j005: "ご利用料金",
          Mail: {
            j001: "メール",
            j002: "希望する",
            j003: "希望しない",
          },
          SMS: {
            j001: "希望する",
            j002: "希望しない",
          },
          Fee: {
            j003: "ご利用料金は、<wbr>診療後の医療機関による<wbr>金額確定時に決済され、<wbr>各クレジットカード会社の<wbr>引き落とし日に<wbr>支払われます。",
            j003a: "ご利用料金は診療後に確定します。​",
            j004: "所属法人からの案内をご確認ください",
          },
        },
        AppointmentFlow: {
          Title: { j001: "この後の流れ" },
        },
        DateTimeFormat: {
          j001: "の枠",
        },
      },
    },
    SearchResultsPage: {
      render: {
        Title: {
          j001: "検索結果",
        },
      },
    },
    UnsupportedServiceBlockPage: {
      render: {
        ServiceBlockContent: {
          j001: "お使いの端末は動作環境外です。",
          j002: "申し訳ございませんが、お使いの端末は、OSバージョンまたはブラウザが動作環境外のため、当システムをご利用いただくことができません。以下の動作環境を満たした端末で再度アクセスしてください。",
          j003: "動作環境",
          j004: "【スマートフォン】",
          j005: "▼Android",
          j006: "・OS：Android 7.0以降",
          j007: "・ブラウザ：Google Mobile Chrome 最新版",
          j008: "▼iOS(iPhone/iPod touch)",
          j009: "・OS：iOS12.2以降",
          j010: "・ブラウザ：Safari 最新版",
          j011: "【タブレット】",
          j012: "▼iOS",
          j013: "・OS：iOS12.2/iPad OS13.1以降",
          j014: "・ブラウザ：Safari 最新版",
          j015: "【パソコン】",
          j016: "▼Windows",
          j017: "・OS：Windows 10",
          j018: "・ブラウザ：Google Chrome 最新版/Microsoft Edge 最新版",
          j019: "▼Mac",
          j020: "・OS：macOS 10.14 Mojave 以降",
          j021: "・ブラウザ：Google Chrome 最新版",
          j022: "※会員登録時と通知受け取り時（任意）は、SMSを受信できる端末でご利用ください。",
          j023: "※ご利用の端末にインカメラとマイクが内蔵していない場合は、別途ご用意ください。",
        },
      },
    },
    VideoStandbyPage: {
      render: {
        Title: "通話待機",
        ContentForNg: {
          j001: "ビデオ通話を開始できません",
          j002: "端末のマイクまたはカメラの設定を変更してください。設定が変更されるまでは、医療機関から通話を開始することができません。",
          j003: "の枠",
          j004: {
            1: "しばらくたっても画面が切り替わらない場合は、「再読み込み」ボタンを押してください。",
            2: "再読み込みをしても状態が変わらない場合は、以下よりご利用端末の設定をご確認ください。",
          },
          j005: "再読み込み",
          j006: "端末のカメラ・マイクの設定方法",
          j007: "Android端末をお使いの方",
          j008: "iPhoneをお使いの方",
          j009: "Windowsをお使いの方",
          j010: "Macをお使いの方",
          j011: "iPadをお使いの方",
        },
        ContentForNormal: {
          j001: "の枠",
          j002: "予約時刻まであと",
          j003: "分",
          j004: "医療機関がビデオ通話を開始するまでしばらくお待ちください",
          j005: "直前の通話が延長しているなどの理由でお待ちいただく場合があります。",
          j006: "この画面で待機している場合のみ、医療機関はビデオ通話を開始することができます。",
          j007: "​※以下の場合は、待機していないとみなされてしまいますのでご注意ください。",
          j008: "・この画面以外で操作をしている",
          j009: "・他のアプリの操作をしている",
          j010: "・この画面を開いたままスマホがロック画面になる（スリープ状態になる）",
          j011: "※万が一、枠の時間が過ぎても通話が始まらない場合は、医療機関へ直接お問い合わせください。",
        },
        ErrorMessage: {
          NotAllowedError: {
            WinChrome: {
              j001: "カメラ・マイクの使用が許可されていません。",
              j002: "画面左上の「鍵マーク」を押し、カメラとマイクを「許可」に設定してください。",
            },
            WinEdge: {
              j001: "カメラ・マイクの使用が許可されていません。",
              j002: "画面左上の「鍵マーク」を押し、カメラとマイクを「許可」に設定してください。",
            },
            MacChrome: {
              j001: "カメラ・マイクの使用が許可されていません。",
              j002: "画面左上の「鍵マーク」を押し、カメラとマイクを「許可」に設定してください。",
            },
            IpadSafari: {
              j001: "カメラ・マイクの使用が許可されていません。",
              j002: "画面左上の「ぁあ」を押し、「Webサイトの設定」からカメラとマイクを「許可」に変更してください。",
            },
            IphoneSafari: {
              j001: "カメラ・マイクの使用が許可されていません。",
              j002: "画面左上の「ぁあ」を押し、「Webサイトの設定」からカメラとマイクを「許可」に変更してください。",
            },
            AndroidChrome: {
              j001: "カメラ・マイクの使用が許可されていません。",
              j002: "画面左上の「鍵マーク」を押し、「権限」からカメラとマイクを「許可」に変更してください。",
            },
          },
          NotFoundError: {
            j001: "カメラまたはマイクを起動することができませんでした。",
            j002: "ご利用の端末のカメラ・マイクの設定を確認してください。",
          },
          NotReadableError: {
            j001: "カメラまたはマイクが他のアプリで使用されています。",
            j002: "カメラ・マイクを使用した他のアプリを閉じてください。",
          },
        },
      },
    },
    EnterpriseTopPage: {
      render: {
        j001: "このアカウントでは以下のプログラムの利用登録が完了しています。​",
      },
    },
    EnterpriseAuthenticationPage: {
      render: {
        j001: "ルナルナ オフィスをご利用の方は、このページから会員登録もしくはログインし、招待コードの入力にお進みください。",
      },
    },
    EnterpriseCompletePage: {
      render: {
        Title: "ルナルナ オフィス利用登録完了",
        Content: {
          j001: "ルナルナ オフィス利用登録が完了しました",
          j002: "以下の「医療機関を選択する」から医療機関を選択し、1回目の診療予約をお取りください。\n予約できる医療機関や診療メニューは事前に法人から受け取ったメールや添付されている利用案内等に記載されています。",
        },
        Button: {
          j001: "医療機関を選択する",
        },
      },
    },
    EnterpriseConfirmPage: {
      render: {
        Title: "登録内容確認",
      },
    },
    EnterpriseAuthorizationPage: {
      render: {
        Title: "招待コード入力",
        Content: {
          j001: "招待コード",
          j002: "ルナルナ オフィスを利用するためには、招待コードを入力してください。\n招待コードは事前に法人から受け取ったメールや添付されている利用案内等に記載されています。",
          j003: "例:12345678",
          j004: "上記の内容に同意する",
          j005: "従業員番号",
          j006: "法人から利用案内等で指定された番号を入力してください。",
          j007: "確認のためもう一度入力してください。",
        },
        Error: {
          j001: "招待コードが未入力です。",
          j002: "無効な招待コードです。入力内容をご確認ください。",
          j003: "半角英数字や半角「‐」で入力してください。",
          j004: "30文字以内で入力してください。",
          j005: "従業員番号が一致しません。",
        },
        Button: {
          j001: "登録内容を確認する",
        },
        Submit: {
          j001: "無効な招待コードです。5回間違えると一定時間コードの入力ができなくなります。",
          j002: "複数回誤った入力がされたため、ロックされました。しばらく時間を空けて再度お試しください。",
          j003: "入力された招待コードは有効な状態ではありません。所属法人のご担当者にお問い合わせください。",
          j004: "入力された招待コードは有効期限が過ぎています。所属法人のご担当者にお問い合わせください。",
          j005: "入力された招待コードに紐づく福利厚生プランが存在しません。所属法人のご担当者にお問い合わせください。",
          j006: "入力された招待コードは、このアカウントに紐づいている法人のものではありません。所属法人のご担当者にお問い合わせください。",
          j007: "入力された招待コードで現在利用可能なプログラムがございません。お渡しした利用案内にて所属法人のプログラム利用期間をご確認ください。",
          j008: "入力された1つ以上の招待コードに紐づく法人が一致しません。所属法人のご担当者にお問い合わせください。",
          j009: "入力された招待コードは、既に登録されています。予約に進んでください。",
          j010: "予期せぬエラーが発生しました。初めからやり直してください。",
          j011: "半角英数字や半角「‐」で入力してください。",
          j012: "30文字以内で入力してください。",
          j013: "従業員番号が一致しません。",
        },
      },
    },
    MedicineDeliveryUpdatePage: {
      render: {
        Title: {
          j001: "お薬の受け取り方法",
        },
        Content: {
          j001: "オンライン診療の結果、薬局から配送でお薬を受け取ることになった場合、",
          j002: "オンライン診療後にご自身で薬局の予約をしてください。",
          j003: "ご希望の薬局を選択してください。オンライン診療後、病院/クリニックが選択した薬局へ処方せんを配送します。",
          j004: "※ここで薬局の予約はできません。",
          j005: "オンラインでお薬の説明を聞いて、登録した住所にお薬を配送してもらう",
          j006: "処方せんは以下の住所に配送されます。\n処方せんは薬局へお持ちいただき、薬剤師によるお薬の説明をうけ、お薬を受け取ってください。",
          j007: "処方せんは以下の住所に配送されます。",
          j008: "※配送先を変更する場合は、予約当日までにマイページから住所を変更してください。",
          j009: "お薬の説明と受取方法の選択",
          j010: "次へ",
          j011: "戻る",
          j012: "オンライン診療の結果、お薬の説明をオンラインで受ける場合、​",
          j013: "オンラインで薬剤師からお薬の説明を受けた後、薬局からご登録の住所にお薬が配送されます。",
          j014: "登録した住所に処方せんを配送してもらい、自分で薬局にお薬を取りに行く",
          j015: "※処方せん・お薬の配送料はご登録のクレジットカードにご請求いたします。\n※オンライン診療の結果、お薬の処方がない場合やご希望の受取方法から変更になる場合があります。",
          j016: "※ご希望の病院/クリニック付近でオンラインでお薬の説明を受けることができる薬局を表示しています。",
          j017: "お薬を配送で受け取るにはオンラインで薬剤師からお薬の説明を受ける必要があります。",
          j018: "ご自身で病院/クリニックと合意した薬局の予約をしてください。\n",
          j019: "現在、表示する薬局はありません。",
        },
      },
    },
  },
  Components: {
    AppointmentReminder: {
      Dialog: {
        DialogTitle: {
          j001: "医療機関側の準備が整いました",
        },
        DialogDescription: {
          j001: " ",
          j002: "他の通話/カメラアプリを起動している場合は、\nアプリを終了してから開始してください。",
        },
        Button: {
          j001: "ビデオ通話を開始する",
        },
      },
    },
    MessageBox: {
      render: {
        Snackbar: {
          Button: {
            j001: "閉じる",
          },
        },
      },
    },
    UnsupportedAlertMessage: {
      j001: "動作環境について",
      j002: "お使いの端末のブラウザのバージョンは、当サービスの動作環境外のため、ビデオ通話が正常に行えない可能性がございます。OSまたはブラウザのバージョンをご確認のうえ、動作環境内でご利用ください。",
      j003: "動作環境を確認する",
    },
  },
  Utils: {
    Error: {
      j001: "大文字と小文字の両方を含めて8文字以上で入力してください。",
      j002: "必ず大文字と小文字の両方を含めてください。",
      j003: "8文字以上で入力してください。",
      j004: "SMSを受信できる電話番号を登録してください。",
    },
    GoogleTagManager: {
      logCustomDimensions: {
        j001: "ログイン",
        j002: "未ログイン",
        j003: "会員",
        j004: "非会員",
        j005: "非課金",
        j006: "WebViewブラウザ",
        j007: "非対応ブラウザ",
        j008: "対応ブラウザ",
      },
    },
    ServiceAlertContent: {
      j001: "お使いの端末は当サービスの動作環境外のため、ビデオ通話が正常に行えない可能性がございます。OSまたはブラウザのバージョンをご確認のうえ、動作環境内でご利用ください。",
      j002: "YYYY年MM月DD日",
      j003: "からご利用いただけなくなります。",
    },
    dateFormat: {
      time: "YYYY/MM/DD(dd) HH:mm",
      period: {
        start: "YYYY/MM/DD(dd) HH:mm~",
        end: "HH:mm",
      },
    },
  },
  InquirySignupSmsCompleted: {
    Heading: "お問い合わせ完了",
    InputFormHeaderNoIcon: "送信完了しました",
    InputFormHeaderNoBackgroundColor: "この後の流れ",
    Step: [
      {
        title: "本人確認",
        subStep:
          "ご登録いただいた電話番号へ、当社より順次本人確認のお電話をいたします。",
      },
      {
        title: "登録完了",
        subStep:
          "本人確認後、当社にて会員登録の手続きをいたします。会員登録が完了しましたら、お電話にて完了のご連絡をいたします。",
      },
    ],
    BwCareful: {
      title: "注意事項",
      symbol: "・",
      j001: "会員登録の手続きには日数がかかります。既に医療機関と日時などを調整されている場合は、医療機関へ直接ご連絡をお願いいたします。 ",
      j002: "入力いただいたメールアドレス宛に「問い合わせ番号」をお送りします。会員登録が完了するまで、メールを破棄しないようお願いいたします。",
    },
  },
};
